.preloader {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  &--is-absolute {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: inherit;
  }

  &__block-sidebar-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  &__block-sidebar-content {
    display: grid;
  }

  &__block-sidebar-wrapper {
    margin-top: 40px;
  }

  &__block-sidebar {
    background-color: #394356;
    border-radius: 6px;
    overflow: hidden;

    &--title {
      width: 148px;
      height: 18px;
    }

    &--subtitle {
      width: 53px;
      height: 18px;
    }

    &--row {
      height: 46px;
      width: 100%;
    }

    &--button {
      width: 117px;
      height: 30px;
      margin-top: 8px;
      align-self: flex-end;
    }
  }

  &__wrapper-button {
    justify-content: flex-end;
  }

  &__matchday {
    width: 100%;
    height: 304px;
    background-color: #394356;
    border-radius: 6px;
  }

  &__header {
    height: 40px;
    width: 100%;
    background: #F0F0F2;
    border-radius: 6px;

    &--space {
      margin-top: 8px;
    }
  }

  &__left-group {
    flex: 1;
    max-width: 186px;
    margin-right: 10px;
  }

  &__row {
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #F0F0F2;
    padding-right: 8px;
    padding-left: 19px;
    justify-content: space-between;
  }

  &__group {
    display: flex;
    flex-direction: column;

    &--fixed {
      flex: 1;

      @media screen and (max-width: 540px) {
        display: none;
      }
    }
  }

  &__block {
    background: #F0F0F2;
    border-radius: 6px;

    &--time {
      width: 38px;
      height: 16px;
      margin-right: 15px;
    }

    &--bet {
      width: 58px;
      height: 24px;
      margin-left: 4px;
    }

    &--favorite {
      width: 24px;
      height: 24px;

      @media screen and (max-width: 540px) {
        display: none;
      }
    }

    &--score {
      width: 16px;
      height: 16px;
      margin-right: 4px;

      @media screen and (max-width: 540px) {
        display: none;
      }
    }

    &--favorite-first {
      margin-right: 8px;
    }

    &--forecast {
      width: 54px;
      height: 24px;
      margin-right: 25px;

      @media screen and (max-width: 540px) {
        display: none;
      }
    }

    &--team {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }

    &--team-circle {
      border-radius: 50%;
    }

    &--title {
      width: 100%;
      height: 14px;

      @media screen and (max-width: 540px) {
        width: 50px !important;
      }

      // @media screen and (max-width: 1250px) {
      // 	width: 90px;
      // }

      // @media screen and (max-width: 1200px) {
      // 	width: 70px;
      // }

      // @media screen and (max-width: 1150px) {
      // 	width: 50px;
      // }
    }

    &--title-one {
      width: 50%;

      // @media screen and (max-width: 1200px) {
      // 	width: 35px;
      // }

      // @media screen and (max-width: 1150px) {
      // 	width: 25px;
      // }
    }
  }

  &--top-countries-section {
    margin-top: 8px;
  }

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    z-index: 1;
    width: 500%;
    margin-left: -250%;
    pointer-events: none;
    content: " ";
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 46%, rgba(255, 255, 255, 0.35) 50%, rgba(255, 255, 255, 0) 54%) 50% 50%;
    -webkit-animation: ph-animation 0.8s linear infinite;
    animation: ph-animation 0.8s linear infinite;
  }

  &--matchday {
    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 50%;
      z-index: 1;
      width: 500%;
      margin-left: -250%;
      pointer-events: none;
      content: " ";
      background: linear-gradient(to right, rgba(255, 153, 0, 0) 46%, rgba(67, 79, 101, 0.26) 50%, rgba(250, 10, 178, 0) 54%) 50% 50%;
      -webkit-animation: ph-animation 0.8s linear infinite;
      animation: ph-animation 0.8s linear infinite;
    }
  }

  &__item {
    width: 100%;
    height: 32px;
    border-radius: 6px;
    background: #F8F8F8;

    &--main {
      background: #F0F0F2;
    }

    &--medium{
      height: 46px;
    }

  }

  &__button {
    width: 121px;
    height: 32px;
    background-color: #F0F0F2;

    background: #F0F0F2;

    border-radius: 6px;
  }
}