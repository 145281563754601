@import 'styles/variables';

// Competition links

.containerLeagueWrapper {
  position: relative;
  width: 100%;
  max-width: 1310px;
  min-height: 56px;

  display: grid;
  grid-template-columns: 280px 1fr;
  grid-column-gap: 16px;
  margin-right: auto;
  margin-left: auto;
  padding: 8px 15px;

  @media (max-width: $tablets) {
    grid-template-columns: 1fr;
  }
}

.linksContainerLoading {
  opacity: 0;
}

.dropdownLeagueLinks {
  width: 40px;
  margin-left: 8px;
}

.linkHidden {
  opacity: 0;
  pointer-events: none;
}