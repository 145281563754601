.Logo {
  flex-shrink: 0;

  &--with-border {
    padding: 4px;
  }

  &__contianer {
    border-radius: 50%;
    background: $white;
  }
}