@import "variables";

.TeamName {
  color: $white;
  font-weight: 700;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media screen and (max-width: 540px) {
    display: none;
  }

  &--hidden {
    display: none;
  }

  &--show {
    display: initial;
  }

  &--font-regular {
    font-weight: 400;
  }
}

.TeamNameShort {
  color: $white;
  font-weight: 700;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media screen and (min-width: 541px) {
    display: none;
  }

  &--hidden {
    display: none;
  }

  &--show {
    display: initial;
  }

  &--font-regular {
    font-weight: 400;
  }
}