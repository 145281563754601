@import 'styles/variables';

.base {
  display: flex;
}

.dirRow {
  flex-flow: row;
}
.dirCol {
  flex-flow: column;
}
.wrap {
  flex-wrap: wrap;
}

.justify_start {
  justify-content: flex-start;
}
.justify_center {
  justify-content: center;
}
.justify_between {
  justify-content: space-between;
}
.justify_end {
  justify-content: flex-end;
}

.align_start {
  align-items: flex-start;
}
.align_center {
  align-items: center;
}
.align_stretch {
  align-items: stretch;
}
.align_end {
  align-items: flex-end;
}

.gap_2 {
  gap: $space-2;
}
.gap_4 {
  gap: $space-4;
}
.gap_8 {
  gap: $space-8;
}
.gap_12 {
  gap: $space-12;
}
.gap_16 {
  gap: $space-16;
}
.gap_32 {
  gap: $space-32;
}

.grow_1 {
  flex-grow: 1;
}
.shrink_0 {
  flex-shrink: 0;
}
