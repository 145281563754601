@import 'styles/variables';

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  min-height: 40px;
  padding: 4px 8px;
  border-radius: 6px;

  font-size: 13px;
  line-height: 16px;
  font-weight: bold;
  text-transform: uppercase;

  transition-property: background-color, color, border-color;
  transition-duration: .15s;
  transition-timing-function: ease-in;

  // Default

  color: $black-40;
  background-color: $gray-light-color;
  &:hover {
    color: $text-black;
    background-color: #e6e6e6;
  }
  &:active {
    color: $text-black;
    background-color: $primary-color;
  }
  &.isActive {
    color: $text-black;
    background-color: $primary-color;
    pointer-events: none;
  }
  &:disabled {
    color: $black-20;
    background-color: $gray-light-color;
    pointer-events: none;
  }

  // Dark

  &.dark {
    color: $white-60;
    background-color: $gray-color;
    &:hover {
      color: $white;
      background-color: $bg-fifth;
    }
    &:active {
      color: $text-black;
      background-color: $primary-color;
    }
    &.isActive {
      color: $text-black;
      background-color: $primary-color;
      pointer-events: none;
    }
    &:disabled {
      color: $white-20;
      background-color: $gray-color;
      pointer-events: none;
    }
  }
}

.badge {
  min-width: 20px;
  padding: 2px;

  color: $white;
  background-color: #ff4848;
  border-radius: 6px;
}