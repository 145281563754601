/* Width and height */

.height-full {
  height: 100%;
}

/* Margins and paddings */

.mb-16 {
  margin-bottom: 16px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-4 {
  margin-bottom: 4px;
}

.mt-64 {
  margin-top: 64px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-4 {
  margin-top: 4px;
}

.mt-0 {
  margin-top: 0 !important;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.px-12 {
  padding: 0 12px;
}

/* Visual */

.opacity-1.opacity-1 {
  opacity: 1;
}
.opacity-04 {
  opacity: .4;
}

/* Text */

.txt-align-center {
  text-align: center;
}

.color-black.color-black {
  color: $text-black;
}
.color-white.color-white {
  color: $white;
}
.color-yellow.color-yellow {
  color: $primary-color;
}

.has-link-hover {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.text-with-overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* Containers */

.overflow-hidden {
  overflow: hidden;
}

.grid-with-overflow-rtl {
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
}
.grid-with-overflow-ltr {
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
}

/* Loading */

.loading-background.loading-background {
  background-color: #394356;
}

.loading-pill {
  min-width: 60px;
  min-height: 16px;
  border-radius: 6px;
  background-color: #394356;
}