.two-series {
  border-radius: 6px;
  background: var(--bg-fourth, #394356);
  padding: 12px;
  margin-left: 12px;
  margin-right: 12px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    background: var(--bg-sixth, #1C222D);
  }


  &__col {
    display: flex;
    align-items: center;
    flex: 1;
    gap: 8px;
    grid-gap: 8px;
    overflow: hidden;

    &--first {
      justify-content: flex-end;
    }

    @media screen and (max-width: 360px) {
      gap: 4px;
      grid-gap: 4px;
    }
  }

  &__title {
    color: var(--text-white-40, rgba(255, 255, 255, 0.40));
    /* 11 CAPS */
    font-family: PT Sans;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    /* 127.273% */
    text-transform: uppercase;
  }

  &__score {
    border-radius: 6px;
    height: 30px;
    width: 58px;
    display: flex;
    gap: 4px;
    grid-gap: 4px;
    justify-content: center;
    align-items: center;
    background: var(--bg-fifth, #282F3D);

    span {
      color: var(--text-white-60, rgba(255, 255, 255, 0.60));
      text-align: center;
      /* 13/Text 13 */
      font-family: PT Sans;
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      /* 123.077% */
    }

  }

  &__team-title {
    overflow: hidden;
    color: var(--text-white-white, #FFF);
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: PT Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;

    @media(max-width: 575px) {
      display: none;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 14px;
  }

  &__score-t {
    width: 15px;
  }

  &__content {
    margin-top: 4px;
    display: flex;
    justify-content: center;
    gap: 8px;
    grid-gap: 8px;
    height: 30px;
    align-items: center;
    font-size: 13px;

    &--info {
      margin-left: 16px;
      margin-top: 0;
      gap: 16px;
      grid-gap: 16px;

      @media screen and (max-width: 360px) {
        gap: 8px;
        grid-gap: 8px;
      }

      .two-series__team-title {
        overflow: unset;

      }

      .two-series__col {
        overflow: unset;
      }

      .two-series__score span {
        color: $white;
      }

      .two-series__score {
        width: 58px;
      }
    }
  }

  &__live {
    position: absolute;
    left: 20px;
    top: 20px;

    @media (max-width: 768px) {
      top: 16px;
      left: 12px;
    }
  }
}