.button {

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-shrink: 0;

  &--close-modal {
    border-radius: 6px;
    display: flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
    opacity: .5;

    &:hover{
      opacity: 1;
    }

    @media screen and (max-width: 500px) {
      opacity: 1;
    }
  }

  &--matchday-link {
    width: 100%;
    height: 40px;
    background-color: #394356;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    color: var(--text-white-white, #FFF);
    /* Button/butText 13 */
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    /* 123.077% */
    letter-spacing: 0.26px;
    text-transform: uppercase;
    transform: translate3d(0, -6px, 0);
    transition: all 0.3s ease-out;

    &:hover {
      background: var(--bg-sixth, #1C222D);
    }
  }

  &--continue {
    display: flex !important;
    font-family: PT Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    /* 123.077% */
    text-transform: uppercase;
    width: 400px;

    @media screen and (max-width: 675px) {
      width: 100%;
    }
  }

  &--password {
    position: absolute;
    right: 8px;
    top: 8px;
    width: 24px;
    height: 24px;

    .form__ico--visible {
      display: block;
    }

    .form__ico--hidden {
      display: none;
    }
  }

  &--password-active {
    .form__ico--visible {
      display: none;
    }

    .form__ico--hidden {
      display: block;
    }
  }

  &--score-switcher {
    transition: all 0.3s ease-out;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border-radius: 36px;
    background: rgba(255, 255, 255, 0.05);

    &:hover {
      background-color: #1C222D;
    }
  }

  &--auth-modal {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 13px;
    margin: 0 auto;
    margin-top: 16px;
    padding-left: 24px;
    padding-right: 24px;

    transition: background-color .15s ease-in;
    &:hover {
      background-color: #ffc700;
    }
  }

  &--soc-auth {
    height: 40px;
    background: #F0F0F2;
    border-radius: 4px;

    transition: background-color .15s ease-in;
    &:hover {
      background-color: #e0e0e0;
    }
  }

  &--auth-modal-right {
    margin-top: 0;
  }

  &--events {
    background-color: #394356 !important;

    span {
      font-weight: 700;
      font-size: 13px;
    }

    img {
      transform: rotate(90deg);
    }
  }

  &--more-odds {
    width: 100%;
    height: 40px;
    background: #394356;
    border-radius: 6px;
    display: flex;
    transition: all 0.3s ease-out;

    img {
      transform: rotate(90deg);
    }

    span {
      color: $white;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 13px;
    }

    &:hover {
      background-color: #1C222D;
    }
  }

  &--matchday-menu {
    width: 24px;
    height: 24px;

  }

  &--forecast-bet {
    height: 30px;
    width: 116px;
    transition: all 0.3s ease-out;
    font-size: 11px;
    font-weight: 700;

    &:hover {
      background-color: #FFC700;
    }
  }

  &--virt-book {
    background-color: #0E8D5F !important;
    width: 116px;
    transition: all 0.3s ease-out;
    font-size: 11px;
    color: $white;
    font-weight: 700;

    &:hover {
      background-color: #0d6e4a !important;
    }
  }

  &--filter-etc {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0 !important;
  }

  &--view {
    width: 24px;
    height: 24px;
    background-color: transparent;
    border-radius: 6px;
    transition: all 0.3s ease-out;

    &:hover {
      background-color: $primary-color;

      .button__ico--view {
        opacity: 1;
      }
    }

    @media screen and (max-width: 360px) {
      display: none;
    }
  }

  &--view-sidebar {
    &:hover {

      .button__ico--view-white {
        opacity: 0 !important;
      }
    }
  }

  &--rating {
    height: 30px;
    background-color: transparent;
    text-transform: uppercase;
    letter-spacing: 0.02em;
    color: $white;
    font-weight: 700;
    font-size: 13px;
    padding-left: 8px;
    padding-right: 8px;
    transition: all 0.3s ease-out;
    align-items: center;

    &:hover {
      background-color: #394356;
    }
  }

  &--view-add {
    opacity: 0;

    @media screen and (min-width: 575px) and (max-width: 992px) {
      opacity: 1;
    }
  }

  &--active-favorite {
    opacity: 1 !important;

    &:hover {
      background-color: transparent;
    }

    img {
      opacity: 1 !important;
    }
  }

  &--filter {
    font-size: 15px;
    border-radius: 6px;
    color: rgba(255, 255, 255, 0.6);
    height: 40px;
    padding-left: 8px;
    padding-right: 8px;
    transition: all 0.3s ease-out;

    &:hover,
    &-active {
      background-color: #394356;
      color: $white;

      .button__ico-filter {
        opacity: 1;
      }
    }

    @media screen and (max-width: 534px) {
      //height: 48px !important;
      //min-height: 48px;
      flex-direction: column !important;
      align-items: center;

    }
  }

  //&--filter-0 {
  //	@media screen and (max-width: 1192px) {
  //		display: none;
  //	}
  //}
  //
  //&--filter-1 {
  //	@media screen and (max-width: 1100px) {
  //		display: none;
  //	}
  //}
  //
  //&--filter-2 {
  //	@media screen and (max-width: 988px) {
  //		display: none;
  //	}
  //}
  //
  //&--filter-3 {
  //	@media screen and (max-width: 900px) {
  //		display: none;
  //	}
  //}
  //
  // &--filter-4 {
  // 	@media screen and (max-width: 500px) {
  // 		display: none;
  // 	}
  // }
  //
  // &--filter-5 {
  // 	@media screen and (max-width: 360px) {
  // 		display: none;
  // 	}
  // }

  // &--filter-6 {
  // 	@media screen and (max-width: 400px) {
  // 		display: none;
  // 	}
  // }

  &--forecast {
    position: absolute;
    left: 50%;
    margin-left: -45px;
    width: 90px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #434F65;
    border-radius: 6px;
    white-space: nowrap;
    bottom: 0;
    transition: all 0.3s ease-out;
    z-index: 1;
    transform: translateY(50%);

    span {
      color: $white;
      font-size: 13px;
      font-weight: 700;
    }

    &:hover {
      background-color: #282F3D;
    }
  }

  &--surebet-win {
    width: 100%;
    transition: visibility 0.3s, opacity 0.3s ease-out;
    opacity: 1;
    visibility: visible;

    span {
      font-size: 12px;
    }
  }

  &--surebet-win-expanded {
    opacity: 0;
    visibility: hidden;
  }

  &--filter-content {
    font-size: 13px;
    border-radius: 6px;
    text-transform: uppercase;
    color: rgba(23, 30, 33, 0.4);
    height: 40px;
    font-weight: 700;
    padding-left: 8px;
    padding-right: 8px;
    transition: all 0.3s ease-out;
    background-color: #ECEDED;
    white-space: nowrap;

    &:hover,
    &-active {
      background-color: $primary-color;
      color: #171E21;

      .button__ico-filter {
        opacity: 1;
      }
    }

    &:disabled {
      pointer-events: none;

      .filter-live-badge {
        background-color: #cfcfd7;
      }
    }
  }

  .filter-live-badge {
    min-width: 20px;
    padding: 2px;
    margin-left: 4px;

    color: $white;
    background-color: #ff4848;
    border-radius: 6px;
  }

  &--filter-content-odds {
    background-color: #394356;
    color: rgba(255, 255, 255, 0.6);

    span {
      transition: all 0.3s ease-out;
      color: rgba(255, 255, 255, 0.6);
    }

    &:hover,
    &-active {
      background-color: $primary-color;

      span {
        color: #171E21;
      }

    }
  }

  &__ico-filter {
    margin-right: 8px;
    opacity: 0.8;
    transition: opacity 0.3s ease-out;

    @media screen and (max-width: 534px) {
      margin-right: 0;
    }

    &--nospace {
      margin: 0;
    }

    &--header {
      &--hide {
        width: 0;
        padding: 0;
        overflow: hidden;
        position: absolute;
      }

      span {
        @media screen and (max-width: 576px) {
          display: none;
        }
      }
    }
  }

  &__ico-filter-footer {
    margin-right: 8px;
    opacity: 0.8;
    transition: opacity 0.3s ease-out;
  }

  &--show-all {
    margin-top: 16px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-transform: uppercase;
    font-weight: 700;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 6px;
    background-color: transparent;
    transition: all 0.3s ease-out;

    &:hover {
      background-color: #F0F0F2;
    }
  }

  &--rating-book {
    &:hover {
      .button__ico--odd-bookmaker {
        opacity: 1 !important;
      }
    }
  }

  &__ico {

    &--show-all {
      margin-left: 4px;
      transform: rotate(90deg);
    }

    &--view {
      transition: all 0.3s ease-out;
      opacity: 0.4;
    }

    &--view-white {
      transition: all 0.3s ease-out;
      opacity: 1;
    }

    &--abs {
      position: absolute;
    }

    &--odd-bookmaker {
      opacity: 1;

    }
  }


  &__text {

    &--show-all {
      color: rgba(23, 30, 33, 0.4);
      font-size: 13px;
    }
  }

  &--filter-all {
    padding: 10px;

    @media screen and (max-width: 534px) {
      height: 48px !important;
      min-height: 48px;
    }
  }

  &--bound {
    min-width: 40px;
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-out;
    border-radius: 6px;

    &:hover {
      background-color: #394356 !important;
    }
  }

  &--active {
    background-color: #394356 !important;
  }

  &--signin {
    padding-left: 24px;
    padding-right: 24px;
    transition: all 0.3s ease-out;
    white-space: nowrap;

    &:hover {
      background-color: #FFC700;
    }

    @media screen and (max-width: 428px) {
      display: none;
    }
  }

  &--rounded {
    border-radius: 6px;
  }

  &--primary-color {
    background-color: $primary-color;
  }

  &--transparent-color {
    background-color: transparent;
    color: $transparent-color;
  }

  &--small-size {
    min-height: 38px;
  }

  &--upper {
    text-transform: uppercase;
  }

  &--footer {
    height: 40px;
    color: rgba(255, 255, 255, 0.4);
    letter-spacing: 0.06em;

    &:hover {
      color: $white;
    }
  }

  &--social {
    background-color: #394356;

    &:hover {
      background-color: #282F3D !important;
    }
  }

  &--not-avalaible {
    color: var(--text-light-graphite, #88949A);
    text-align: center;
    /* 11 CAPS */
    font-family: PT Sans;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    /* 127.273% */
    text-transform: uppercase;
    background-color: var(--bg-modal, rgba(8, 13, 21, 0.60)) !important;

    &:hover,
    :active,
    :focus {
      background-color: var(--bg-modal, rgba(8, 13, 21, 0.60));
    }
  }

  &--matchday-menu-top {
    min-width: 30px;
    height: 30px;
    border-radius: 6px;
    background: var(--bg-fifth, #232A38);
    transition: all 0.3s ease-out;

    &:hover {
      background: var(--bg-sixth, #1C222D);
      opacity: 1;
    }
  }

  &--with-star-fav {
    svg {
      stroke: transparent;
      stroke-width: 1px;
      transition: all .3s ease-out;
    }

    &:hover {
      svg {
        stroke: #171e2166;
      }
    }
  }
}