.list {
  &--footer {
    display: inline-block;
    display: inline-flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-left: -8px;
  }

  &--footer-bonus {
    margin-left: 0;
    width: 90%;
    overflow: hidden;

    @media screen and (max-width: 510px) {
      width: 100%;
    }
  }

  &__ico {
    width: 20px;
    height: 20px;
    margin-right: 8px;

    &--search-result {
      width: 40px;
      height: 40px;
      margin-right: 16px;
      color: #171E21;
    }

    &--flag {
      width: 20px;
      height: 20px;
      border: 1px solid $white;
      position: absolute;
      right: -5px;
      bottom: -5px;
      border-radius: 50%;
      overflow: hidden;
    }
  }

  &__item {
    position: relative;

    &--footer {
      height: 40px;
      display: flex;
    }

    &--footer-bonus {
      margin-bottom: 4px;
      background-color: #394356;
      border-radius: 8px;
      overflow: hidden;
    }

    &--sidebar {
      height: 40px;
    }

    &--search-result {
      height: 56px;
    }
  }

  &__subtitle {
    color: rgba(23, 30, 33, 0.4);
  }

  &__link-ico {
    transition: all 0.3s ease-out;

    &--competitions {
      position: absolute;
      right: 8px;
    }
  }

  &__link {
    cursor: pointer;

    &--inside {
      height: 40px;
      padding-left: 36px !important;
      color: rgba(23, 30, 33, 0.6) !important;

      font-size: 13px !important;
    }

    &--icon {
      flex-shrink: 0;
    }

    &--image {
      padding-left: 8px !important;
    }

    &--footer {
      display: flex;
      align-items: center;
      height: 100%;
      font-weight: 700;
      letter-spacing: 0.06em;
      border-radius: 6px;
      padding-left: 8px;
      padding-right: 8px;
      transition: all 0.3s ease-out;
      color: rgba(255, 255, 255, 0.4);
      font-size: 15px;

      &:hover {
        background-color: #434F65;
        color: $white;

        .button__ico-filter {
          opacity: 1;
        }
      }
    }

    &--footer-bonus {
      width: 100%;
      color: $white !important;
      overflow: hidden;

      &:hover {
        color: $white;

        .best-offers__img-hovered, .best-offers__item-overlay{
          opacity: 1!important;
        }
      }

      @media screen and (max-width: 510px) {
        text-align: center;
        display: flex;
        justify-content: flex-start;
      }
    }

    &--search-result {
      display: flex;
      align-items: center;
      height: 100%;
      color: #171E21;
      border-radius: 6px;
      padding-left: 8px;
      padding-right: 8px;
      font-size: 13px;
      transition: background-color 0.3s ease-out;

      &:hover {
        background-color: #ECEDED;
      }
    }

    &--sidebar {
      display: flex;
      align-items: center;
      gap: 8px;
      height: 100%;

      border-radius: 6px;
      padding-left: 8px;
      padding-right: 8px;
      font-size: 13px;
      transition: all 0.3s ease-out;
      color: #171E21;

      &:hover {
        background-color: #ECEDED;
      }
    }
  }
}