.searchBlur {
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  z-index: 1;

  background-color: rgba(#080D15, .6);
  animation: overlayShow .4s cubic-bezier(0.16, 1, 0.3, 1) forwards;
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}