@import 'styles/variables';

.surebet {

  .slider {
    width: 100%;

    .slick-slide {
      padding-right: 0;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;

    .breadcrumb {
      color: $white-60;
    }
  }

  &__input {
    width: 100%;
    background: transparent;
    border: none;
    color: $white;
    text-align: center;
    font-size: 13px;
    font-weight: 700;
    font-family: 'PT Sans';
  }

  &__input-wrapper {
    height: 30px;
    display: flex;
    align-items: center;
    border: 1px solid $white;
    border-radius: 6px;
    width: 85px;
    justify-content: flex-end;

    &--stavka {
      margin-left: 8px;
    }
  }

  &__input-wrapper-currency {
    border-left: 1px solid $white;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 30px;
    color: $white;
    font-size: 13px;
    font-weight: 700;
    height: 28px;
  }

  &__wrapper-bet {
    display: flex;
    opacity: 0;
    transition: opacity 0.3s ease-out;
    align-items: center;
    justify-content: flex-end;
    height: 30px;

    &--active {
      opacity: 1;
    }
  }

  &__wrapper-bet-title {
    font-weight: 700;
    font-size: 13px;
    text-transform: uppercase;
    color: $white;
  }

  &__lefted {
    position: absolute;
    left: 0;
  }

  &__bet-content {
    transition: all 0.3s ease-out;
  }

  &__bet-footer {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    left: 0;
    right: 0;
    transition: all 0.3s ease-out;
    height: 54px;
    top: 124px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);

    &:hover {
      background: #1C222D;
    }
  }

  &__expanded-title {
    font-weight: 700;
    font-size: 13px;
    text-transform: uppercase;
    color: $white;
    padding-left: 8px;
  }

  &__expanded-result {
    height: 46px;
    border-radius: 6px;
    background: #282F3D;
    position: absolute;
    opacity: 0;
    left: 0;
    right: 0;
    top: 186px;
    transition: all 0.3s ease-out;
    display: flex;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
    justify-content: space-between;
  }

  &__grid {
    display: flex;
    margin-top: 8px;
    position: relative;
    margin-bottom: 8px;

    &--expanded {
      .surebet__bet-header {
        border-bottom: 1px solid rgba(255, 255, 255, 0);
      }

      .surebet__bet-content {
        transform: translate3d(0, -16px, 0);
      }

      .surebet__bet {
        max-height: 178px;
        height: 178px;
      }

      .surebet__bet-footer,
      .surebet__expanded-result {
        opacity: 1;
      }

      .surebet__main-block {
        height: calc(100% - 54px);
      }
    }
  }

  &__title-wrapper {
    height: 30px;
    width: 100%;
    position: relative;
  }

  &__main-block {
    transition: all 0.3s ease-out;
    height: 100%;

    &:hover {
      background: #1C222D;
    }
  }

  &__bet-header {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    margin-bottom: 16px;
    transition: all 0.3s ease-out;
    font-size: 13px;
    font-weight: 700;
    color: $white;
    text-transform: uppercase;
  }

  &__bet {
    cursor: pointer;
    background: #282F3D;
    border-radius: 6px;
    max-height: 140px;
    height: 140px;
    position: relative;
    overflow: hidden;
    flex: 1;
    transition: all 0.3s ease-out;

  }
}