@import 'styles/variables';

.containerMain {
  max-width: 1280px;
  margin: 0 auto;
}

.containerLinks {
  margin-top: 32px;

  display: grid;
  grid-template-columns: repeat(4, max-content);
  justify-content: space-between;
  gap: 16px;

  @media screen and (max-width: 830px) {
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto auto;
    gap: 32px;

    :nth-child(3) {
      grid-column: 2;
    }
    :nth-child(4) {
      grid-column: 1;
      grid-row: 2;
    }
  }

  @media screen and (max-width: 500px) {
    grid-template-columns: 1fr;
    grid-auto-rows: auto;

    :nth-child(3) {
      grid-column: 1;
    }
    :nth-child(4) {
      grid-row: 3;


      :global(.flex) {
        width: 100%;
      }
      .linkTopBonus {
        justify-content: center;
      }
    }
  }
}

.containerWithPadding {
  padding: 0 8px;
}

.columnLinks {
  min-width: 152px;

  display: flex;
  flex-flow: column;
  align-items: flex-start;
  gap: 16px;
}
.columnTitle {
  color: $white;
  font-size: 15px;
  line-height: 18px;
  font-weight: bold;
  letter-spacing: 0.06em;
  text-transform: uppercase;
}

.linkItem {
  min-height: 40px;

  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;

  color: $white-40;
  font-size: 15px;
  line-height: 18px;
  font-weight: bold;
  letter-spacing: 0.06em;

  border-radius: 6px;

  transition-property: color, background-color;
  transition-duration: .1s;
  transition-timing-function: ease-in;

  &:hover {
    color: $white;
    background-color: $gray-secondary-color;
  }
}

// Bonus link

.linkTopBonusContainer:not(:last-child) {
  margin-bottom: 4px;
}

.linkTopBonus {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 5px 8px;

  color: $white-60;
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  text-transform: uppercase;

  border-radius: 8px;
  background-color: $gray-color;

  transition-property: color, background-color;
  transition-duration: .1s;
  transition-timing-function: ease-in;

  &:hover {
    color: $white;

    .bonusOverlay {
      opacity: 1;
    }
    .bonusLogoColored {
      opacity: 1;
    }
  }

  @media screen and (max-width: 992px) {
    color: $white;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.06em;
  }
}

.bonusLogoContainer {
  z-index: 1;

  width: 80px;
  height: 30px;

  display: flex;
  align-items: center;
  justify-content: center;
}
.bonusLogoColored {
  opacity: 0;
  position: absolute;
  transition: opacity .1s ease-in;
}

.bonusOverlay {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 0;

  border-radius: inherit;

  opacity: 0;
  transition: opacity .1s ease-in;
}


.bonusTextLong {
  z-index: 1;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media screen and (max-width: 992px) {
    display: none;
  }
}
.bonusTextShort {
  z-index: 1;

  display: none;
  @media screen and (max-width: 992px) {
    display: initial;
  }
}