@import "./variables";

.rating-list {
  margin-top: 40px !important;

  &.forecast {
    content-visibility: auto;
  }

  &__team-title {
    color: $white;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;

    transition: all 0.3s ease-out;

    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;

    &--short {
      display: none;

      @media screen and (max-width: 360px){
        display: inline;
      }
    }

    &--full {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @media screen and (max-width: 360px){
        display: none;
      }
    }
  }

  &__formation {
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    padding-left: 4px;
    padding-right: 4px;
    height: 22px;
    border-radius: 6px;

    span {
      color: $white;
      font-weight: 700;
      font-size: 11px;
    }

    &--left {
      left: 12px;
      bottom: 12px;
    }

    &--right {
      right: 12px;
      bottom: 12px;
    }
  }

  &__team-wrapper {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    &--field {
      width: 40px;
      height: 40px;
      position: absolute;
    }

    &--field-left {
      left: 8px;
      top: 8px;
    }

    &--field-right {
      right: 8px;
      top: 8px;
    }
  }

  &__row-subheader {
    width: 100%;
    height: 48px;
    border-radius: 6px;
    background-color: #394356;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    &--lineup {
      @media screen and (max-width: 650px) {
        display: none;
      }
    }
  }

  &__filter-ico {
    transform: rotate(180deg);
    opacity: 0.6;

    &--asc {
      transform: rotate(0deg);
      opacity: 1;
    }

    &--desc {
      opacity: 1;
    }
  }

  &__block-use {
    margin-bottom: 16px;
  }

  &__row-subtitle {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    /* identical to box height, or 123% */

    display: flex;
    align-items: flex-end;
    text-align: center;
    justify-content: center;
    /* Text/White/60% */

    color: rgba(255, 255, 255, 0.6);
  }

  &__col-header {
    width: 58px;
    justify-content: center;
    display: flex;
    cursor: pointer;


    span {
      font-weight: 700;
      font-size: 13px;
      line-height: 16px;
      /* identical to box height, or 123% */
      text-align: center;
      text-transform: uppercase;
      white-space: nowrap;
      /* Text/White/White */
      color: #FFFFFF;
      opacity: 0.6;
    }

    align-items: center;

    &--last {
      width: 74px;
      display: none;
      align-items: center;
      justify-content: center;
      height: 30px;

      &:hover {
        .match-events__tooltip--payout {
          display: block;
        }
      }
    }
  }

  &__avatar-letter {
    font-family: 'Montserrat';
    color: $white;
    font-style: italic;
    font-weight: 800;
    font-size: 15px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
  }

  &__avatar-wrapper {
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid $white;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      border-radius: 50%;
    }

    &--lineups {
      padding: 0;
      width: 20px;
      height: 20px;
    }
  }

  &__title-subheader {
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    /* identical to box height, or 123% */
    text-align: center;
    text-transform: uppercase;
    /* Text/White/White */
    color: #FFFFFF;
    opacity: 0.6;
  }

  &__subheader {
    padding-left: 12px;
    padding-right: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 16px;
    margin-bottom: 16px;

    &--odds {
      margin-bottom: 8px;
      position: relative;
    }

    &--lineups {
      margin-bottom: 8px;

      .rating-list__subtitle {
        text-transform: none;
        font-size: 13px;
      }

    }
  }

  &__right {
    display: flex;
    justify-content: space-between;
  }

  &__left {
    max-width: 200px;
    overflow: hidden;
    width: 100%;
  }

  &__title-price {
    display: inline-block;
    text-align: center;
    height: 24px;
    line-height: 24px;
    background-color: $primary-color;
    border-radius: 6px;
    color: #171E21;
    width: 38px;
  }

  &__span {
    font-size: 13px;
    font-weight: 700;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 16px;
  }

  &__td {
    display: flex;
    align-items: center;
    color: $white;

    &--overflow {
      overflow: hidden;
    }

    &--pos-lineup {
      min-width: 18px;
      text-align: right;
      justify-content: flex-end;
      font-size: 13px;
      font-weight: 700;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      line-height: 16px;
    }

    &--flag {
      min-width: 30px;
      margin-right: 10px;
      position: relative;

      &:hover {
        .match-events__tooltip {
          display: block;
        }
      }
    }

    &--sub {
      min-width: 16px;
      height: 20px;

      margin-right: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &--number {
      min-width: 30px;
      text-align: left;
      font-size: 13px;
      font-weight: 700;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      line-height: 16px;
    }

    &--pos {
      min-width: 30px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      font-size: 13px;
      font-weight: 700;
    }

    &--price {
      font-size: 13px;
      font-weight: 700;
      justify-content: space-between;

    }

    &--total {
      font-size: 13px;
      font-weight: 700;
      width: 105px;
      justify-content: flex-end;
    }

    &--name {
      font-size: 13px;
      font-weight: 700;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      line-height: 16px;
    }
  }

  &__column-subheader {
    padding-top: 16px;
    padding-bottom: 8px;
  }

  &__footer {
    padding-top: 7px;
    text-align: right;
    display: flex;
    justify-content: flex-end;

    &--odds {
      padding-top: 4px;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 18px;
    margin-bottom: 16px;

    &--padding {
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  &__price {
    color: $white;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    text-transform: uppercase;

    &--odd {
      font-weight: 400;
      opacity: 0.6;
    }
  }


  &__title {
    color: $white;
    letter-spacing: 0.06em;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    text-transform: uppercase;

    &--odds {
      text-transform: none;
    }
  }

  &__subtitle {
    color: rgba(255, 255, 255, 0.6);
    text-transform: uppercase;
    font-weight: 700;
    font-size: 11px;
    line-height: 16px;

    &--left {
      padding-right: 12px;
    }
  }

  &__event {

    &--odd {
      .rating-list__row {
        background-color: transparent;
      }
    }

    &--coach {
      margin-top: 16px;

      .rating-list__row {
        background-color: #394356;
      }
    }
  }

  &__link {
    position: relative;

    &:nth-child(even) .rating-list__row {
      background-color: transparent;
    }

    &:hover .rating-list__row {
      background-color: #1C222D;

      .rating-list__price--odd {
        opacity: 1;
      }

      .button__ico--odd-bookmaker {
        // opacity: 0;
      }
    }


  }

  &__content {

    &--grid {
      display: grid;
      grid-gap: 4px;
      gap: 4px;
    }

    &--lineups {
      display: grid;
      grid-gap: 16px;
      gap: 16px;
      grid-template-columns: 2fr 2fr;

      @media screen and (max-width: 650px) {
        grid-template-columns: 1fr;
      }
    }

    &--sidebar {
      grid-template-columns: 1fr;

      .rating-list__row-subheader--lineup {
        display: none;
      }

      .active-team-tab {
        display: initial;
      }
      .hidden-team-tab {
        display: none;
      }
    }
  }

  &__row {
    height: 46px;
    background: #394356;
    border-radius: 6px;
    transition: all 0.3s ease-out;
    padding-left: 12px;
    padding-right: 12px;
    justify-content: space-between;
    display: flex;
    cursor: pointer;

    &--virtual {
      display: flex;
      justify-content: space-between;
    }

    &--last-virtual {
      margin-top: 8px;
      background: rgba(14, 141, 95, 0.6) !important;
    }

    &--lineups {
      height: 44px;
      cursor: default;
    }

    &--ended {
      cursor: default;
    }
  }
}