.main-wrapper {
  width: 100%;
  max-width: 1310px;
  padding: 16px 15px 0 15px;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    padding: 0;
  }
}

.wrapper-with-sidebar-left {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-column-gap: 12px;

  @media screen and (max-width: 860px) {
    grid-template-columns: 1fr;
  }
}