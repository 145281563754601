@import 'styles/variables';

.base {
  position: relative;

  @media (max-width: $tablets) {
   display: none;
  }
}

.inputWrapper {
  height: 40px;
  padding: 6px 8px;

  border-radius: 12px;
  background-color: rgba($white, 0.05);
  transition: background-color .15s ease-in;
  &:hover {
    background-color: rgba($white, 0.15)
  }
  &:focus-within .inputLabel {
    cursor: default;
  }
}
.inputLabel {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
}
.input {
  position: relative;
  flex-grow: 1;
  color: $white;
  font-size: 14px;

  &::placeholder {
    color: $white-40;
  }
}
.close {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: opacity .15s ease-in;
  opacity: .6;
  &:hover {
    opacity: 1;
  }
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;

  width: 375px;
  min-height: 360px;
  max-height: 80vh;

  border-radius: 6px;
  background-color: $white;

  transform: translateY(4px);
  overflow: auto auto;

  /* Style scrollbar */

  // For webkit-based browsers
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: $stroke_hover;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  // For Firefox
  scrollbar-color: $stroke_hover transparent;
  scrollbar-width: thin;

  /* Animation */

  transition: height .4s ease-in-out;
  animation: slideUpAndFade .4s cubic-bezier(0.16, 1, 0.3, 1) forwards;
}

.tabsContainer {
  padding: 12px;
  border-bottom: 1px solid $stroke_hover;
}

.sectionHeader {
  padding: 12px;
  color: $black-40;
  font-size: 13px;
  line-height: 16px;
  font-weight: bold;
  text-transform: uppercase;
}

.loadPill {
  height: 56px;
  border-radius: 6px;
  border: 1px solid $white;

  background: linear-gradient(to right, #f8f8f8 0%, rgba(200, 200, 200, 0.2) 8%, rgba(200, 200, 200, 0.3) 18%, rgba(200, 200, 200, 0.2) 33%, #f8f8f8 100%);
  background-size: 750px 56px;
  animation: loading-waves 1.5s infinite ease-in-out;
}

.notFound {
  min-height: 295px;
}

@keyframes loading-waves {
  0% {
    background-position: -375px 0;
  }
  100% {
    background-position: 375px 0;
  }
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(4px);
  }
}