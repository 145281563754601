@import "./variables";

.match-item {
  width: 100%;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-radius: 6px;
  height: 74px;

  &:nth-child(odd) {
    background: $gray-color;
  }

  &__top {
    display: grid;
    grid-template-columns: minmax(0, 150px) auto minmax(0,150px);
    grid-column-gap: 8px;
    justify-content: space-between;
    align-items: center;
    position: relative;
    font-weight: 700;
    line-height: 1.27;
    text-transform: uppercase;
    font-size: 11px;
    color: rgba(255, 255, 255, 0.40);
    content-visibility: auto;

    & span:last-child {
      text-align: right;
    }
  }

  &__competition {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__competition:hover + .match-item__tooltip {
    display: block;
  }

  &__tooltip {
    display: none;
    position: absolute;
    background: #282F3D;
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 74px 30px rgba(0, 0, 0, 0.01), 0 42px 25px rgba(0, 0, 0, 0.03), 0 18px 18px rgba(0, 0, 0, 0.04), 0 5px 10px rgba(0, 0, 0, 0.05), 0 0 0 rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    top: 24px;
    z-index: 9999;
    padding: 12px;
    width: max-content;
    color: white;
  }

  &__center {
    text-align: center;
    @media (max-width: 767px) {
      display: none;
    }

    &--mobile {
      display: none;
      @media (max-width: 767px) {
        display: block;
      }
    }
  }

  &__inner{
    display: grid;
    grid-template-columns: minmax(0, 1fr) 58px minmax(0, 1fr);
    gap: 8px;
    content-visibility: auto;
  }

  &__logo {
    padding: 4px;
    border-radius: 50%;
    background: white;
    width: 26px;
    height: 26px;
  }

  &__team {
    display: flex;
    align-items: center;
    gap: 8px;

    color: $gray-light-color;
    font-weight: 700;

    &:first-child {
      justify-content: right;
    }
  }

  &__name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 13px;
  }

  &__score {
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    padding: 7px 8px;
    background: #232A38;
    color: rgba(255, 255, 255, 0.60);
    letter-spacing: 3px;
    border-radius: 6px;
    text-align: center;

    &--red {
      color: #FF4848
    }

    &--green {
      color: #15B400;
    }
  }
}