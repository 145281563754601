.table {
  &__content {
    &--hidden {
      overflow: hidden;
    }

    transition: max-height 0.3s ease-out;
  }

  &--match {
    outline: 1px solid #ECEDED;
    border-radius: 6px;

    @media screen and (max-width: $tablet) {
      border-radius: 0px;
    }

    &:hover {
      .table__header-col--bet {
        opacity: 1;
      }
    }

    @media screen and (max-width: 360px) {
      .wrapper-card-ico {
        display: none;
      }
    }
  }

  &__text {
    color: rgba(23, 30, 33, 0.4);
    text-align: center;

    font-size: 12px;

    &--bold {
      font-weight: 700;
    }

    &--red {
      color: $primary-red-color;
    }

    &--uppercase {
      text-transform: uppercase;
    }
  }

  &__right {
    padding-left: 4px;
    border-left: 1px solid #ECEDED;
    height: 44px;
    align-items: center;
    position: relative;

    @media screen and (max-width: $tablet) {
      border-left: none;
    }

    @media screen and (max-width: 360px) {
      padding-left: 0;
    }

    @media screen and (max-width: $mobile) {
      border-left: none;
    }
  }

  &__block-title {
    font-weight: 400;
    font-size: 14px;

    &--strong {
      font-weight: 700;
    }

    &--red {
      color: $primary-red-color;
    }

    &--black {
      color: #171E21;
    }

    &--gray {
      color: rgba(23, 30, 33, 0.6) !important;
    }
  }

  &__block {

    &--result {
      height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 18px;
    }

    &--result-no {
      @media screen and (max-width: 575px) {
        display: none;
      }
    }
  }

  &__header {
    z-index: 2;

    &--match {
      background: #ECEDED;
      border-radius: 6px;
      height: 40px;
      justify-content: space-between;
      padding-left: 8px;
      padding-right: 8px;
      border-bottom: 1px solid #ECEDED;
      cursor: pointer;

      @media screen and (max-width: $tablet) {
        border-radius: 0;
      }
    }

    &--favorite {
      background-color: #FFF8D2;
      border-bottom: 1px solid #ECEDED;
    }

    &--expanded {
      .list__link-ico--competitions {
        transform: rotate(180deg);
      }
    }

    &--opened {
      .list__link-ico--competitions {
        opacity: 0;
      }

      &:hover {
        .list__link-ico--competitions {
          opacity: 1 !important;
        }

        .table__header-col--bet {
          opacity: 0;
        }
      }

    }
  }

  &__header-title {
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: #171E21;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    transition: all 0.3s linear;
  }

  &__col {

    &--time {
      width: 60px;
      min-width: 60px;
      display: flex;
      justify-content: center;
      position: relative;
      padding-right: 8px;

      @media screen and (max-width: 428px) {
        width: 40px;
        min-width: 40px;
        margin-right: 4px;
      }
    }

    &--tooltip {
      cursor: pointer;
    }

    &--team {

      display: grid;

    }

    &--empty {
      width: 24px;

      @media screen and (max-width: 428px) {
        display: none;
      }
    }

    &--result {
      width: 18px;
    }

    &--forecast {
      margin-right: 8px;
    }
  }

  &__row {
    font-size: 13px;

    &--match-last {
      border-bottom: none;
    }

    .table__col--favorite {
      @media screen and (min-width: 429px) and (max-width: 575px) {
        display: none !important;
      }
    }

    &--match-upcoming {
      .table__col--result {

        @media screen and (max-width: 429px) {
          display: none !important;
        }
      }
    }

    &--match {
      height: 60px;
      border-bottom: 1px solid #ECEDED;
      cursor: pointer;
      background-color: $white;
      padding: 8px;
      display: flex;
      align-items: center;
      gap: 4px;
      grid-gap: 4px;
      justify-content: space-between;

      &:hover {
        background-color: #F6F6F6;

        .button--view-add {
          opacity: 1;
        }
      }
    }

    &--match-last {
      border-bottom: none;
    }
  }

  &__header-col {

    &--bet {
      width: 58px;
      height: 16px;
      text-transform: uppercase;
      color: rgba(23, 30, 33, 0.4);
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      transition: all 0.3s ease-out;
      opacity: 0.6;

      @media screen and (max-width: 992px) {
        width: 54px;
      }

      @media screen and (max-width: 428px) {
        display: none;
      }
    }
  }
}