.match-events {
  display: grid;
  padding: 16px 12px;
  background: #394356;
  border-radius: 6px;

  &__period-title {
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    /* identical to box height, or 123% */

    white-space: nowrap;
    /* Text/White/White */

    color: #FFFFFF;

    // &:first-letter {
    // 	text-transform: uppercase;
    // }
  }

  &__period-header {
    display: flex;
    align-items: center;
    height: 30px;
    justify-content: space-between;

  }

  &__tooltip-text {
    font-weight: 400;
    font-size: 13px;
    color: rgba(255, 255, 255, 0.6);
    white-space: nowrap;
  }

  &__incindent-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    position: relative;
    width: 16px;
    height: 16px;

    &:hover .match-events__tooltip {
      display: block;
    }

    &--lineups {
      margin-left: 4px;
    }
  }

  &__incindent-ico-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;

    width: 24px;
    min-width: 24px;
    height: 24px;
    /* BG/Fifth

    Hover / Stroke
    */
    background: #282F3D;
    border-radius: 6px;
    position: relative;

    &:hover {
      .match-events__tooltip {
        display: block;
      }
    }


  }

  &__event {
    display: flex;
    align-items: center;
    height: 24px;
    width: 50%;
    justify-content: flex-end;

    &--team-1 {
      padding-right: 8px;
    }

    &--team-2 {
      flex-direction: row-reverse;
      margin-left: auto;
      padding-left: 8px;
    }
  }

  &__period-header-content {
    margin-left: 15px;
    margin-right: 15px;
    display: flex;
    align-items: center;
  }

  &__line {
    width: 100%;
    height: 1px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 1px;
  }

  &__score-wrapper {
    background: #282F3D;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 8px;
    gap: 4px;
    height: 30px;
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    /* identical to box height, or 123% */

    text-align: center;

    /* Text/White/60% */

    color: rgba(255, 255, 255, 0.6);
  }

  &__add-time-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4px;

    span {
      font-weight: 400;
      font-size: 11px;
      line-height: 14px;
      /* identical to box height, or 127% */

      text-transform: uppercase;

      /* Text/White/40% */

      color: rgba(255, 255, 255, 0.4);
    }
  }

  &__title-player {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-align: right;
    color: #FFFFFF;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    b {
      font-weight: 400;
      opacity: 0.4;
    }
  }

  &__title-time {
    font-size: 13px;
    line-height: 16px;
    position: relative;
    min-width: 35px;
    width: 35px;
    text-align: center;

    /* identical to box height, or 123% */
    white-space: nowrap;

    /* Text/White/60% */

    color: rgba(255, 255, 255, 0.6);
  }

  &__content-wrapper {
    padding-top: 16px;
    display: grid;
  }

  &__score-wrapper-inner {
    display: flex;
    align-items: center;
  }

  &__score {
    width: 16px;
    height: 16px;
  }



  &__tooltip-content {
    margin-top: 8px;
    display: grid;
  }

  &__tooltip-title {
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    /* identical to box height, or 123% */
    white-space: nowrap;
    text-transform: uppercase;

    &--lineups {
      text-transform: none;
      white-space: normal;

      &:first-letter {
        text-transform: uppercase;
      }
    }

    /* Text/White/White */

    color: #FFFFFF;
  }

  &__tooltip {
    position: absolute;
    display: none;
    background: #282F3D;
    /* BG/White10% (архив) */

    border: 2px solid rgba(255, 255, 255, 0.1);
    /* First Shadow (Select) */

    box-shadow: 0px 74px 30px rgba(0, 0, 0, 0.01), 0 42px 25px rgba(0, 0, 0, 0.03), 0 18px 18px rgba(0, 0, 0, 0.04), 0 5px 10px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    top: 24px;
    z-index: 9999;
    padding: 12px;
    width: max-content;

    &--payout {
      max-width: 240px;
      width: 240px;
      right: 0;
      top: 50px;

      background: #282F3D;
      border: 2px solid rgba(255, 255, 255, 0.1);
      box-shadow: 0px 74px 30px rgba(0, 0, 0, 0.01), 0 42px 25px rgba(0, 0, 0, 0.03), 0 18px 18px rgba(0, 0, 0, 0.04), 0 5px 10px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05);
      border-radius: 6px;
      outline: 2px solid rgba(255, 255, 255, 0.2);

      .match-events__tooltip-title {
        white-space: wrap !important;
        font-weight: 400;
      }
    }

    &--payout-top {
      top: 24px;
    }
  }

  &__tooltip-row {
    display: flex;
    align-items: center;
    white-space: nowrap;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    /* identical to box height, or 123% */


    /* Text/White/White */

    color: #FFFFFF;
  }

  &__title-addtime {
    vertical-align: super;
    font-size: xx-small !important;
  }

  &__title-no-events {
    margin: 0;
  }
}