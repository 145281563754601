.news {
  display: grid;

  &__item {
    padding: 8px;
    cursor: pointer;
    background-color: #394356;
    border-radius: 6px;
    transition: background-color 0.3s ease-out;
    content-visibility: auto;

    &:hover {
      background-color: #282F3D;
    }
  }

  &__image {
    border-radius: 6px;

  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__title {
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: $white;
  }

  &__subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: rgba(255, 255, 255, 0.4);
  }
}