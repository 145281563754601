.date-filter {
  max-width: 127px;
  margin-right: auto;

  &__input {
    display: flex;
    justify-content: space-between;

    border-radius: 6px;
    border: 1px solid $container_shadow-main;
  }
  &__input-time {
    height: 40px;
    padding-right: 4px;

    border-radius: 6px;
    border: 1px solid $container_shadow-main;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 40px;
    transition: background-color 0.3s ease-out;
    border-radius: 6px;

    &:hover {
      background-color: #f6f6f6;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    color: rgba(23, 30, 33, 0.4);
  }

  &__content {
    padding: 8px;
    cursor: pointer;
    border-radius: 6px;
    transition: background-color 0.3s ease-out;

    &:hover {
      background-color: #F6F6F6;
    }
  }

  &__wrapper-calendar {
    position: absolute;
    z-index: 4;
    left: 16px;
    top: 56px;

    max-width: 250px;
    outline: 2px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 8px 64px rgba(0, 0, 0, 0.2);

    .react-calendar {
      width: 100%;
      outline: none;
      box-shadow: none;
      border-radius: 6px 6px 0 0;
      padding-bottom: 4px;
    }

    // Hide day highlight, when time period selected
    .calendar-time-active .react-calendar__tile--active {
      background-color: unset !important;
    }

    @media (max-width: 768px) {
      left: 4px;
    }
  }

  &__preloader {
    background: #434F65;
    width: 250px;
    height: 260px;
    border-radius: 8px;
  }

  &__footer {
    padding: 0 12px 12px 12px;

    border-radius: 0 0 6px 6px;
    background-color: $white;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: #f0f0f2;
      margin-bottom: 8px;
    }
  }

  &__time-control {
    height: 40px;
    padding: 12px 8px;

    font-size: 13px;
    color: rgba(23, 30, 33, 0.40);

    white-space: nowrap;

    border-radius: 6px;

    cursor: pointer;
    &:hover {
      background-color: #f8f8fa;
    }

    &_active {
      color: #222;
      font-weight: bold;
      background-color: $primary-color;

      &:hover {
        background-color: $primary-color;
      }
    }
  }
}