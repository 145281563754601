.maker-block {
  width: 100%;
  background: #282F3D;
  border-radius: 6px;
  height: 108px;
  margin-top: 8px;
  container-type: inline-size;
  container-name: sidebar;
  
  &--small {
    .maker-block__content {
      padding-right: 0;
    }
  }

  &__content {
    display: grid;
    padding-top: 8px;
    padding-right: 90px;
    @media screen and (max-width: 458px) {
      padding-right: 0;
    }
  }

  &__subtitle {
    font-weight: 700;
    font-size: 11px;
    line-height: 14px;
    /* identical to box height, or 127% */

    text-transform: uppercase;

    /* Text/White/40% */

    color: rgba(255, 255, 255, 0.4);
  }

  &__row {
    width: 100%;
    height: 30px;
    display: flex;
    padding-left: 12px;
    padding-right: 12px;
    align-items: center;
    justify-content: space-between;

    &--small {
      height: 16px;
    }
  }

  &__header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    height: 38px;
    align-items: center;
    display: flex;
    padding-left: 12px;
    padding-right: 12px;
  }

  &__title {
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    /* identical to box height, or 123% */

    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;

    /* Text/White/White */

    color: #FFFFFF;
  }
}