@import './variables';

.last-matches {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-left: 12px;
  padding-right: 12px;
  margin-top: 40px;
  position: relative;

  &__commands {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 4px;
    gap: 4px;
    width: 100%;
    background: $gray-color;
    border-radius: 6px;

    .last-matches__option {
      width: 100%;

      img {
        padding: 4px;
        border-radius: 50%;
        background: white;
        width: 26px;
        height: 26px;
      }
    }
  }

  &__title {
    color: #ffffff;
    letter-spacing: 0.06em;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    text-transform: uppercase;
  }

  &__filter {
    display: flex;
    gap: 8px;
  }

  &__option {
    padding: 7px 5px;
    border-radius: 6px;
    background: $gray-color;
    color: rgba(255, 255, 255, 0.60);
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.26px;
    text-transform: uppercase;
    border: 0;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;

    transition: background-color .15s ease-in;

    &:hover {
      background-color: #1C222D;
    }

    &--active {
      pointer-events: none;
      color: #171E21;
      background:  $primary-color;
      transition: none;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__inner {
    position: relative;
    display: flex;
    gap: 16px;
    align-items: flex-start;
    width: 100%;
    background-color: #434f65;
  }

  &__skeleton {
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 5px;
    overflow: hidden;
    position: relative;
  }
  &__skeleton-bar {
    height: 70px;
    border-radius: 6px;
    background-color: #394356;
  }
}