.breadcrumb {
  list-style: none;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  overflow: hidden;


  &--block {
    color: rgba(255, 255, 255, 0.4);
    text-transform: uppercase;
    font-weight: 700;
    font-size: 11px;
    line-height: 16px;
  }

  &__link {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &--comp {
      color: var(--text-black-60, rgba(23, 30, 33, 0.60));
      font-size: 11px;
      font-style: normal;
      font-weight: 700;
      line-height: 14px;
      /* 127.273% */
      text-transform: uppercase;
      transition: all 0.3s ease-in-out;

      &:hover{
        opacity: 0.6;
      }
    }
  }

  &__match {
    display: block;
    flex: 1;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.breadcrumb .breadcrumb-item:last-child .breadcrumb__link {
  text-overflow: ellipsis;
  overflow: hidden;
}



.breadcrumb-item+.breadcrumb-item::before {
  padding-right: 5px;
  padding-left: 5px;
  width: 12px;
  margin-left: 5px;
  margin-right: 5px;
  opacity: 0.4;
  height: 12px;
  content: "";
  background-image: url("../public/img/arrow-gray.svg");
  background-repeat: no-repeat;
  background-position: center center;
  
}

.breadcrumb-item {
  display: inline;
  align-items: center;
  transition: all 0.3s ease-out;

  &:hover {
    color: $white;
  }

  &--comp{
    &::before{
      background-image: url("../public/img/arrow-g.svg")!important;
      opacity: 1!important;
    }
  }

  &-disabled {
    pointer-events: none;
  }
}