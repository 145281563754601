.badge {
  background-color: $gray-secondary-color;
  padding: 4px;
  border-radius: 6px;
  display: inline-block;

  &--surebet-top {
    background-color: #FFDC21;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-left: 8px;
    padding-right: 8px;
    height: 30px;
    font-size: 13px;
    font-weight: 700;
    color: #171E21;
  }

  &--tour {
		border-radius: 6px;
		background: var(--bg-fourth, #394356);
		padding: 4px 8px;
		color: var(--main-white, #FFF);
		/* 13/Text 13 CAPS */
		font-size: 13px;
		font-style: normal;
		font-weight: 700;
		line-height: 16px;
		/* 123.077% */
		text-transform: uppercase;
		margin-top: 40px;

    @media screen and (max-width: 768px) {
			display: none;
		}
	}

	&--tour-first {
		margin-top: 8px;
	}

  &--matchday {
    z-index: 1;
    border: 1px solid $white;
    color: $white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-left: 4px;
    padding-right: 4px;
    background-color: transparent;
    font-size: 11px;
    font-weight: 700;
    height: 24px;
    margin-bottom: 8px;
    text-transform: uppercase;
  }

  &--matchday-live {
    border: 1px solid $primary-red-color;
    color: $primary-red-color;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-left: 4px;
    padding-right: 4px;
    background-color: transparent;
    font-size: 11px;
    font-weight: 700;
    height: 24px;
    margin-bottom: 8px;
    text-transform: uppercase;
  }

  &--matchday-live-end {
    border: 1px solid $white;
    color: $white;
  }

  &--forecast-table {
    padding-left: 4px;
    padding-right: 4px;
    background-color: transparent;
    border-radius: 6px;
    height: 24px;
    display: flex;
    align-items: center;
    border: 1px solid rgba(23, 30, 33, 0.4);
    color: rgba(23, 30, 33, 0.4);
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 700;

    @media screen and (max-width: 540px) {
      display: none;
    }
  }

  &--forecast-win {
    border-color: #0E8D5F;
    color: #0E8D5F;
  }

  &--forecast-lose {
    border-color: #FF4848;
    color: #FF4848;
  }

  &--forecast-back {
    border-color: #338AF3;
    color: #338AF3;
  }

  &--surebet {
    background-color: #282F3D;
    padding: 0 8px;
    color: $white;
    height: 30px;
    line-height: 30px;
    font-size: 13px;
    font-weight: 700;
    transition: all 0.3s ease-out;
  }

  &--ex {
    background: #ffffff26;
    color: $white;
    text-transform: uppercase;
    font-size: 11px;
    height: 24px;
    display: flex;
    align-items: center;
    padding: 0;
    padding-left: 4px;
    padding-right: 4px;
    font-weight: 700;
  }

  &--main {
    display: block;
    color: #171E21;
    font-weight: 700;
    background-color: $primary-color;
    text-transform: uppercase;

  }

  &--match-day {
    margin-top: 8px;
    font-size: 11px;
    height: 24px;
    line-height: 24px;
    padding: 0 4px;
    display: inline-block;
  }

  &--footer-18 {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__desktop {
    @media (max-width: 768px) {
      display: none;
    }
  }

  &__mobile {
    display: none;
    @media (max-width: 768px) {
      display: block;
    }
  }

  &--dark {
    background-color: #232A38;
  }
}