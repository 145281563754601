.bet-badge {
  padding: 11px 8px;
  border-radius: 6px;
  background-color: #282F3D;

  &__text {
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: $white;
    text-align: center;
  }
}

.bet-wrapper.bet-wrapper--up::before {
  display: block;
}

.bet-wrapper.bet-wrapper--down::after {
  display: block;
}

.bet-wrapper {
  $root: &;

  width: 58px;
  transition: all 0.3s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  border: 1px solid #ECEDED;
  border-radius: 6px;
  position: relative;
  background-color: transparent;
  cursor: pointer;

  &--finished {

    &:hover{
      background: transparent!important;
      border: 1px solid #ECEDED!important;

      .bet-title--finished{
        color: rgba(23, 30, 33, 0.4)!important;
      }
    }
  }

  &--hidden {
    visibility: hidden;
  }

  &--no-betslip {
    position: relative;
    background-image: url("../public/img/pattern_bet.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
    cursor: default !important;

    &:hover {
      background-color: transparent !important;
      border: 1px solid rgba(255, 255, 255, 0.15) !important;

      .bet-title {
        color: $white !important;
      }

      .tooltip--odds {
        display: block;
      }
    }
  }

  &--total {
    border: 0;

    .bet-title {
      color: #171E2166;
    }
  }

  &::before {
    content: '';
    display: none;
    position: absolute;
    background-image: url("../public/img/bet-up-green.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
    width: 5px;
    height: 5px;
    top: 4px;
    right: 4px;
  }

  &::after {
    content: '';
    display: none;
    position: absolute;
    background-image: url("../public/img/bet-down-red.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
    width: 5px;
    height: 5px;
    bottom: 4px;
    left: 4px;
  }


  @media screen and (max-width: 992px) {
    height: 44px;
    width: 54px;
  }

  &:not(.bet-wrapper--total):hover {
    background-color: $primary-color;
    border: 1px solid $primary-color;

    .bet-title {
      color: #171E21;
    }
  }

  &--odds {
    border: 1px solid rgba(255, 255, 255, 0.15);
    height: 30px;

    .bet-title {
      color: $white;
      padding: 0;
    }
  }

  &--best-odds {
    border: 1px solid $primary-color;

    .bet-title {
      color: $primary-color;
    }
  }

  &--maker-odd {
    &:hover {
      border: 1px solid $complementary-color;
      background-color: $complementary-color;

      .bet-title {
        color: $white;
      }
    }
  }

  &--maker#{$root}--maker {
    cursor: default;
    border: 1px solid transparent;
    height: 16px;

    .bet-title {
      font-weight: 700;
      font-size: 13px;
      line-height: 16px;
      /* identical to box height, or 123% */

      display: flex;
      align-items: flex-end;
      text-align: center;

      /* Text/White/40% */

      color: rgba(255, 255, 255, 0.4);
    }

    &:hover {
      background-color: transparent;
      border: 1px solid transparent;

      .bet-title {
        color: rgba(255, 255, 255, 0.4);
      }
    }
  }

}

.bet-title {
  font-size: 13px;
  padding-left: 2px;
  font-weight: 700;
  color: #171E21;

  &--gray {
    color: rgba(23, 30, 33, 0.4);
  }

  &--finished {
    color: rgba(23, 30, 33, 0.4);
    font-weight: 400;
  }

  &--won {
    font-weight: 700;
  }

  transition: all 0.3s ease-in-out;
}

.bet-ico {
  position: absolute;
  left: 4px;
}

.bet {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 6px;
  margin-left: 32px;

  &--surebet {
    padding-left: 8px;
    padding-right: 8px;
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }

  &__title {
    color: $white;
    font-weight: 700;
    font-size: 13px;
  }
}

.odds-ended {
  .bet-wrapper--best-odds {
    border-color: rgba(255, 255, 255, 0.15);
  }

  .bet-wrapper:hover {
    cursor: default;
    background: transparent;

    border-color: rgba(255, 255, 255, 0.15) !important;

    .bet-title {
      color: #ffffff26 !important;
    }
  }

  .bet-title {
    color: rgba(255, 255, 255, 0.4) !important;
  }

  .bet-wrapper--no-betslip {
    background: transparent;
  }

  .bet-wrapper--no-betslip .bet-title {
    color: #ffffff26 !important;
  }
}