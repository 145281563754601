.react-calendar {
	width: 248px;
	max-width: 100%;
	background-color: #fff;
	color: #222;
	padding: 12px;
	font-size: 13px;
	border: none;
	outline: 2px solid rgba(255, 255, 255, 0.2);
	box-shadow: 0px 8px 64px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
	border-radius: 6px;
	font-family: 'PT Sans', sans-serif!important;
  }
   
  .react-calendar__navigation button {
	color: rgba(23, 30, 33, 0.4);
	text-transform: uppercase;
	min-width: 44px;
	background: none;
	font-size: 16px;
  }
   
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
	background-color: white!important;
  }
  .react-calendar__navigation button[disabled] {
	background-color: white!important;
  }
  abbr[title] {
	text-decoration: none;
  }
   
  /* .react-calendar__month-view__days__day--weekend {
	color: #d10000;
   } */
   
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
	background: #f8f8fa;
	color: #171E21;
	border-radius: 6px;
  }
  .react-calendar__tile{
	padding: 8px 6.6667px;
	border-radius: 6px;
  }
  .react-calendar__tile--now {
	background: white;
	border-radius: 6px;
	font-weight: normal;
	color: #171E21;
  }
 
  .react-calendar__tile--active, .react-calendar__tile--active:enabled:focus {
	background: #FFDC21!important;
	color: #171E21;
	font-weight: bold;
  }
   
   
   
  .react-calendar__tile--rangeStart {
	border-radius: 6px;
	background: #FFDC21;
	color: #171E21;
	font-weight: 700;
	font-size:13px;
  }
   
.react-calendar__month-view__days__day--weekend{
	color: #171E21;
}
  .react-calendar__month-view__days__day--neighboringMonth{
	color: rgba(23, 30, 33, 0.2);
  }
  .react-calendar__navigation{
	border: 1px solid #ECEDED;
	border-radius: 6px;
	height: 40px;
	align-items: center;
	margin-bottom: 4px;

  }

  .react-calendar__month-view__weekdays__weekday{
	color: rgba(23, 30, 33, 0.6);
	font-weight: 400;
	font-size: 13px;
	line-height: 20px;
	height: 32px;
	
  }

  .react-calendar__navigation__arrow{
	height: 100%;
  }

  .react-calendar__navigation__label{
	
	
  }
  .react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus{
	background-color: #fff;
	
  }

  .react-calendar__navigation__label__labelText--from{
	color: rgba(23, 30, 33, 0.4);
	font-weight: 700;
  }