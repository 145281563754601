@import './variables';
@import './layout';
@import "./MatchItem";
@import "./LastMathces";
@import "./MakerBlock";
@import "./List";
@import "./Badge";
@import "./RatingList";
@import "./TwoSeries";
@import "./MatchEvents";
@import "./Preloader";
@import "./Buttons";
@import "./MatchDay";
@import "./Table";
@import "./BreadCrumbs";
@import "./SureBet";
@import "./DateFilter";
@import "./Bet";
@import "./News";
@import "./helpers";
@import "./Logo";
@import "./Team";

html {
	scroll-behavior: smooth;
}

.body_fixed {
	overflow: hidden;
}


.show-desktop {
	display: block;

	@media screen and (max-width: 429px) {
		display: none;
	}
}

.show-mobile {
	display: none !important;

	@media screen and (max-width: 429px) {
		display: block !important;
	}
}

.switch-team {
	height: 48px;
	border-radius: 6px;
	background-color: $gray-color;
	padding: 4px;
	width: 100%;
	display: none;
	cursor: pointer;
	position: relative;

	@media screen and (max-width: 650px) {
		display: flex;
	}

	&__content {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		display: flex;
		z-index: 1;
	}

	&__inner {
		width: 50%;
		background-color: $primary-color;
		height: 100%;
		border-radius: 6px;
		transform: translate3d(0, 0, 0);
		transition: all 0.1s ease-out;

		&--one {
			transform: translate3d(0, 0, 0);
		}

		&--two {
			transform: translate3d(100%, 0, 0);
		}
	}

	&__block {
		width: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 8px;
		padding: 8px;
	}
}

.active-team-tab {
	@media screen and (max-width: 650px) {
		display: block;
	}
}

.hidden-team-tab {
	@media screen and (max-width: 650px) {
		display: none;
	}
}


.no-wrap {
	white-space: nowrap;
}

//$tabletlet: 1280px;
* {
	box-sizing: border-box;
}

.flex-justify {
	justify-content: space-between;
}

.flex-center {
	justify-content: center;
}

.flex-column {
	flex-direction: column;
}

body {
	font-family: 'PT Sans', sans-serif !important;
	font-weight: 400;
	background-color: $gray-light-color;
}

.bg-content {
	background-color: $white;
	border-top-left-radius: 6px;
	border-bottom-left-radius: 6px;
}

.content {
	flex-grow: 1;
	background-color: $white;
	border-bottom-left-radius: 6px;
	border-top-left-radius: 6px;
	margin-top: 15px;

	&--league {
		flex-direction: column;
		background-color: transparent;
		margin-top:10px;

		.layout-main-with-sidebar--full {
			margin-top: 0;
		}
	}

	@media screen and (max-width: 1068px) {
		border-radius: 6px;
	}

	@media screen and (max-width: 768px) {
		border-radius: 0;
		margin-top: 0;
	}


	@media screen and (max-width: $mobile) {
		width: 100%;
		overflow: hidden;
	}

	&__main {
		min-height: 500px;
		padding: 12px;
		width: 62%;
		display: flex;
		flex-direction: column;
		position: relative;

		&--hidden {
			display: none;
		}

		@media screen and (max-width: 1150px) {
			width: 100%;
		}

		@media screen and (max-width: $tablet) {
			padding-left: 0;
			padding-right: 0;
		}

		&__keep-padding {
			padding-left: 12px !important;
			padding-right: 12px !important;
		}
	}
}


//@todo Replace html-layout with class .content on more stable .layout-main-with-sidebar
.layout-main-with-sidebar {
	$root: &;

	display: grid;
	grid-template-columns: 1fr 394px;
	overflow: hidden;

	flex-grow: 1;
	background-color: $white;
	border-bottom-left-radius: 6px;
	border-top-left-radius: 6px;
	margin-top: 12px;

	& > .content__main {
		width: 100%;
	}

	& > .sidebar {
		width: auto;
	}

	@media screen and (max-width: 1190px) {
		grid-template-columns: 1fr 346px;
	}
	@media screen and (max-width: 1150px) {
		display: flex;
		flex-flow: column;
	}
	@media screen and (max-width: 1068px) {
		border-radius: 6px;
	}
	@media screen and (max-width: 768px) {
		border-radius: 0;
		margin-top: 0;
	}
	@media screen and (max-width: $mobile) {
		width: 100%;
		overflow: hidden;
	}

	&--full#{$root}--full {
		grid-template-columns: 1fr;
	}
}

.flex-align {
	align-items: center;
}

.circle-ico {
	width: 5px;
	height: 5px;
	border-radius: 50%;
	background-color: #D9D9D9;
}

.input {
	border: none;
	height: 100%;
	width: 100%;
}

.search-box {
	position: relative;

	&__ico {
		position: absolute;
		z-index: 1;
		left: 10px;
		top: 10px;
		width: 20px;
		height: 20px;
	}

	&--sidebar {
		border-radius: 6px;

		&-active,
		&:hover {
			box-shadow: 0 0 0 4px rgba(23, 30, 33, 0.05);
		}

	}

	&__input {
		font-size: 15px;


		&::placeholder {
			/* Most modern browsers support this now. */
			color: rgba(23, 30, 33, 0.4);
		}

		&--sidebar {
			height: 40px;
			border-radius: 6px;
			border: 1px solid $gray-complementary-color;
			color: rgba(23, 30, 33, 0.4);
			padding-left: 40px;
		}
	}
}

.sticky {
	position: relative;
	z-index: 9998;
}

.sidebar {
	padding: 12px;
	border-radius: 6px;
	min-width: 208px;
	position: relative;
	flex-shrink: 0;



	&--left {
		width: 240px;
		max-width: 240px;
		min-height: 500px;
		margin-top: 15px;
		padding: 0;
		gap: 12px;
		display: flex;
		flex-direction: column;

		@media screen and (max-width: 1294px) {
			max-width: 208px;
			min-width: 208px;
		}

		@media screen and (max-width: 860px) {
			display: none;
		}
	}

	&--leagues{
		margin-top: 10px;
	}

	&__wrapper-block {
		padding-left: 12px;
		padding-right: 12px;
	}

	&--right {
		background-color: $gray-secondary-color;
		width: 38%;
		padding: 12px 0 32px;
		min-height: 500px;
		min-width: 330px;

		@media screen and (max-width: 1150px) {
			display: none;
		}
	}

	&--full {
		width: 100%;
		display: block;
		min-width: 100%;

		@media screen and (max-width: 768px) {
			border-radius: 0;
		}
	}

	&__title {
		text-transform: uppercase;
		color: #171E21;
		font-weight: 700;
		font-size: 15px;
		line-height: 18px;
		margin-bottom: 8px;
	}

	&__block {
		background-color: $white;
		border-radius: 6px;
		padding: 12px;
	}
}
.sidebar-content-container {
	width: 100%;
	max-width: 768px;
	margin-left: auto;
	margin-right: auto;
}

.container {

	width: 100%;
	position: relative;
	margin-right: auto;
	margin-left: auto;
	padding-left: 15px;
	padding-right: 15px;
	max-width: $l + $safe-zone;

	&--row {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	&--footer {
		content-visibility: auto;
		margin-top: 32px;
		gap: 32px 0;
	}

	&--mobile {
		@media screen and (max-width: 768px) {
			padding: 0;
			flex-direction: column;
		}
	}
	&--bread{
		padding-top: 10px;
	}

	@media screen and (max-width: 1268px) {
		width: 100%;
	}
}

.flex {
	display: flex;
}
.flex-wrap {
	flex-flow: wrap;
}

.flex-grow {
	flex-grow: 1;
}

.grid-main {
	display: grid;
}

.grid {
	&--2 {
		grid-gap: 2px;
		gap: 2px;
	}

	&--4 {
		grid-gap: 4px;
		gap: 4px;
	}

	&--8 {
		grid-gap: 8px;
		gap: 8px;
	}

	&--10 {
		grid-gap: 10px;
		gap: 10px;
	}

	&--12 {
		grid-gap: 12px;
		gap: 12px;
	}

	&--16 {
		grid-gap: 16px;
		gap: 16px;
	}

	&--18 {
		grid-gap: 18px;
		gap: 18px;
	}

	&--24 {
		grid-gap: 24px;
		gap: 24px;
	}

	&--40 {
		grid-gap: 40px;
		gap: 40px;
	}
}

.search-result {
	position: absolute;
	width: 420px;
	height: 390px;
	padding: 8px 2px 8px 8px;
	background-color: $white;
	border-radius: 6px;
	z-index: 1;
	top: 46px;
	outline: 2px solid rgba(255, 255, 255, 0.2);
	box-shadow: $dropdown-shadow;
}

.menu {
	scroll-behavior: smooth;
	height: 100%;

	&--competition {
		height: 50px;
		display: flex !important;
		font-size: 14px !important;

		@media screen and (max-width: 768px) {
			border-top: 1px solid var(--bg-third, #434F65);
			padding-left: 12px;
			padding-right: 12px;
		}
	}

	&__arrow {
		margin-left: 3px;
		opacity: 0.4;
		transition: all 0.3s ease-out;
	}


	&--sidebar {
		position: relative;
		border-bottom: 1px solid rgba(255, 255, 255, 0.20);
		padding-left: 12px;
		font-size: 14px !important;
		padding-right: 50px;
		grid-gap: 36px;
		gap: 36px;
		font-size: 15px;
		font-weight: 700;
		text-transform: uppercase;
		letter-spacing: 0.06em;
		//justify-content: space-between;

		overflow-x: auto;
		margin-top: 40px;

		.menu__item::after {
			content: none;
		}

		.menu__item {
			width: auto;
		}

		.menu__item--active {
			.menu__title {
				opacity: 1;
			}
		}

		// Hide scrollbar
		&::-webkit-scrollbar {
			display: none;
		}
		scrollbar-width: none;
		-ms-overflow-style: none;
	}

	&--top {
		font-size: 15px;
		font-weight: 700;
		text-transform: uppercase;
		letter-spacing: 0.06em;

		@media screen and (max-width: 968px) {
			display: none;
		}
	}

	&--white {
		color: $white;
	}

	&__title {
		transition: all 0.3s ease-out;
		opacity: 0.4;
		white-space: nowrap;
	}

	&__item {
		position: relative;
		display: flex;
		align-items: center;
		cursor: pointer;
		height: 100%;

		color: $white-40;
		transition: color .1s ease-in;

		&--active {
			color: $white;
			&::after {
				opacity: 1;
			}
		}

		&:hover {
			color: $white;
			.menu__title,
			.menu__arrow {
				opacity: 1;
			}
		}

		&::after {
			content: "";
			transition: all 0.3s ease-out;
			opacity: 0;
			border-radius: 1px;
			position: absolute;
			left: 0;
			right: 0;
			bottom: -1px;
			height: 2px;
			background-color: $primary-color;
		}

		&--active {
			&::after {
				content: "";
				opacity: 1;
				border-radius: 1px;
				position: absolute;
				left: 0;
				right: 0;
				bottom: -1px;
				height: 2px;
				background-color: $primary-color;
			}
		}
	}

	&__link {}

	&__highlight {
		position: absolute;
		height: 2px;
		left: 0;
		bottom: 0;

		background-color: #ffdc21;
		transition: all .3s ease-out;
	}
}

.space-4 {
	margin-left: 4px;
}

.main-logo {
	opacity: 1;
	visibility: visible;
	transition: visibility 0.3s, opacity 0.3s ease-out;
}

.header {
	left: 0;
	right: 0;
	top: 0;
	height: auto !important;
	z-index: 8001;
	background: linear-gradient(156.57deg, #45526D -58.71%, #252E3F 87.01%);

	&--open-search {
		.main-logo {
			@media screen and (max-width: 700px) {
				opacity: 0;
				visibility: hidden;
				display: none;
			}
		}
	}

	&__avatar {
		min-width: 32px;
		min-height: 32px;
		border: 1px solid rgba(255, 255, 255, 0.2);
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		cursor: pointer;
	}

	&__ico-search {
		position: absolute;

		&--left {
			left: 8px;
		}

		&--right {
			right: 8px;
		}
	}

	&__search-input {
		width: 100%;
		height: 100%;
		padding-left: 40px;
		background-color: transparent;
		color: $white;
		border: none;

		font-size: 15px;

		&::placeholder {
			/* Most modern browsers support this now. */
			color: rgba(255, 255, 255, 0.6);
		}
	}

	&__search {
		position: absolute;
		display: flex;
		align-items: center;
		top: 0;
		background: #353e4f;
		border-radius: 6px;
		bottom: 0;
		right: 0;
		z-index: 1;
		width: 421px;
		visibility: hidden;
		opacity: 0;
		transition: visibility 0.3s, opacity 0.3s ease-out;

		&--open {
			opacity: 1;
			visibility: visible;
		}

		@media screen and (max-width: 821px) {
			width: 100%;
			left: 0;
		}
	}

	&__menu-tools {
		width: 100%;
		justify-content: flex-end;

		@media screen and (max-width: 320px) {
			grid-gap: 2px;
			gap: 2px;
		}
	}

	&--top {
		align-items: center;
		display: flex;
		justify-content: space-between;
		position: relative;
		height: 56px;
	}

	&--top-wrapper {
		border-bottom: 1px solid #FFFFFF33;
	}

	&__center-section {
		height: 56px;
		visibility: visible;
		opacity: 1;
		transition: visibility 0.3s, opacity 0.3s ease-out;

		&--hidden {
			visibility: hidden;
			opacity: 0;
		}

		@media screen and (max-width: 821px) {
			display: none;
		}
	}

	&__right-section {
		display: flex;

		@media screen and (max-width: 821px) {
			width: 100%;
			flex-direction: row;
			justify-content: flex-end;
		}
	}
}

.wrapper {
	position: relative;

	&--lng {
		@media screen and (max-width: $tablet) {
			display: none;
		}
	}

	&--gamb {
		display: none;

		@media screen and (max-width: $tablet) {
			display: block;
		}
	}

	&--favorite {
		width: 24px;
		height: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&--favorite-header {
		margin-right: -4px;
	}
}

.font-bold {
	font-weight: 700;
}


.submenu {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 56px;

	.dropdown__list .button__ico-filter {
		margin-right: 8px;
	}

	&__right {
		width: 100%;
	}

	&__title-ico {
		font-size: 15px;
		line-height: 18px;
		text-align: center;
	}

	&--content-filter {
		height: auto !important;
		gap: 8px 4px;
		width: 100%;
		justify-content: flex-start;
		transition: all 0.3s ease;
		padding: 0 4px 16px;
		overflow-x: auto;

		@media screen and (max-width: 768px) {
			padding-left: 12px;
			padding-right: 12px;
		}

		.button {
			transition: all 0.3s ease;
			// position: absolute;
		}
	}

	&__ico {
		&--filter {
			margin-right: 4px;
		}
	}
}

.layout {
	position: relative;
	isolation: isolate;

	width: 100%;
	min-height: 100vh;
	display: flex;
	flex-flow: column;

	&__content {
		display: flex;
		flex-flow: column;
		flex-grow: 1;
		flex-shrink: 1;
	}
}

.no-animate {
	.table__content {
		transition: none !important;
	}

}

.language-switcher {
	border-radius: 4px;
	position: absolute;
	z-index: 9000;
	right: 0;
	transform: translate3d(0, 5px, 0);

	outline: 2px solid rgba(255, 255, 255, 0.2);
	box-shadow: $dropdown-shadow;
	background-color: $white;
	padding: 8px;
	display: grid;
	grid-auto-rows: 40px;
	grid-template-columns: repeat(3, 1fr);
	gap: 8px 16px;

	&--up {
		transform: translate3d(0, calc(-100% - 50px), 0);
	}

	&__button {
		display: flex;
		align-items: center;
		padding: 8px;
		height: 40px;
		border-radius: 6px;
		transition: all 0.3s ease-out;
		min-width: 93px;
		color: #171E21;
		font-size: 13px;
		line-height: 16px;
		white-space: nowrap;

		&--active {
			background-color: #ECEDED;
		}

		&:hover {
			background-color: #ECEDED;
		}
	}

	&__ico {
		width: 20px;
		height: 20px;
		margin-right: 8px;
	}
}

.table__header--favorite .button--view:hover {
	background-color: transparent;
}

.matches-bets {
	@media screen and (max-width: $tablet) {
		display: none;
	}
}

.content-block {
	padding-top: 8px;
	grid-gap: 8px;
	gap: 8px;
	display: grid;

	&--visibility-auto {
		content-visibility: initial;
	}

	&--first {
		@media screen and (max-width: $tablet) {
			padding-top: 0;
		}

	}

	@media screen and (max-width: $tablet) {
		// padding-top: 0;
	}
}

.content-header {
	height: 40px;
	padding-left: 12px;
	padding-right: 8px;
	background: linear-gradient(156.57deg, #45526D -58.71%, #252E3F 87.01%);
	border-radius: 6px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media screen and (max-width: $tablet) {
		border-radius: 0;
	}

	&__title {
		font-weight: 700;
		font-size: 15px;
		line-height: 18px;
		letter-spacing: 0.06em;
		text-transform: uppercase;
		color: $white;
	}
}

.table {
	&__content {
		&--hidden {
			overflow: hidden;
		}

		transition: max-height 0.3s ease-out;
	}



	&--match {
		outline: 1px solid #ECEDED;
		border-radius: 6px;

		@media screen and (max-width: $tablet) {
			border-radius: 0;
		}

		&:hover {
			.table__header-col--bet {
				opacity: 1;
			}
		}

		@media screen and (max-width: 360px) {
			.wrapper-card-ico {
				display: none;
			}
		}
	}

	&--competition {
		outline: none;

		.table__row--match {
			border-left: 1px solid #ECEDED;
			border-right: 1px solid #ECEDED;
		}
	}

	&__text {
		color: rgba(23, 30, 33, 0.4);
		text-align: center;

		font-size: 12px;

		&--bold {
			font-weight: 700;
		}

		&--red {
			color: $primary-red-color;
		}

		&--uppercase {
			text-transform: uppercase;
		}
	}

	&__right {
		padding-left: 4px;
		border-left: 1px solid #ECEDED;
		height: 44px;
		align-items: center;
		position: relative;

		@media screen and (max-width: $tablet) {
			border-left: none;
		}

		@media screen and (max-width: 360px) {
			padding-left: 0;
		}

		@media screen and (max-width: $mobile) {
			border-left: none;
		}
	}

	&__block-title {
		font-weight: 400;
		font-size: 14px;

		&--strong {
			font-weight: 700;
		}

		&--red {
			color: $primary-red-color;
		}

		&--black {
			color: #171E21;
		}

		&--gray {
			color: rgba(23, 30, 33, 0.6) !important;
		}
	}

	&__block {

		&--result {
			height: 22px;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 18px;
		}

		&--result-no {
			@media screen and (max-width: 575px) {
				display: none;
			}
		}
	}

	&__header {
		z-index: 2;

		&--match {
			background: #ECEDED;
			border-radius: 6px;
			height: 40px;
			justify-content: space-between;
			padding-left: 8px;
			padding-right: 8px;
			border-bottom: 1px solid #ECEDED;
			cursor: pointer;

			@media screen and (max-width: $tablet) {
				border-radius: 0;
			}
		}

		&--favorite {
			background-color: #FFF8D2;
			border-bottom: 1px solid #ECEDED;
		}

		&--expanded {
			.list__link-ico--competitions {
				transform: rotate(180deg);
			}
		}

		&--opened {
			.list__link-ico--competitions {
				opacity: 0;
			}

			&:hover {
				.list__link-ico--competitions {
					opacity: 1 !important;
				}

				.table__header-col--bet {
					opacity: 0;
				}
			}

		}
	}

	&__header-title {
		position: relative;
		letter-spacing: 0.06em;
		text-transform: uppercase;
		color: #171E21;
		font-weight: 700;
		font-size: 13px;
		line-height: 16px;
		transition: all 0.3s linear;

		&__shadow {
			content: '';
			position: absolute;

			height: 100%;
			width: 25px;

			&--left {
				left: 0;
				background: linear-gradient(90deg, #F0F0F2 10.53%, rgba(240, 240, 242, 0.00) 100%);
			}

			&--right {
				right: 0;
				background: linear-gradient(270deg, #F0F0F2 10%, rgba(240, 240, 242, 0.00) 80%);
			}

			opacity: 0;
			transition: opacity .8s;

			&--active {
				opacity: 1;
			}
		}
	}

	&__col {

		&--time {
			width: 60px;
			min-width: 60px;
			display: flex;
			justify-content: center;
			position: relative;
			padding-right: 8px;

			@media screen and (max-width: 428px) {
				width: 40px;
				min-width: 40px;
				margin-right: 4px;
			}
		}

		&--tooltip {
			cursor: pointer;
		}

		&--team {

			display: grid;

		}

		&--empty {
			width: 24px;

			@media screen and (max-width: 428px) {
				display: none;
			}
		}

		&--result {
			width: 18px;
		}

		&--forecast {
			margin-right: 8px;
		}
	}

	&__row {
		font-size: 13px;

		&--match-last {
			border-bottom: none;
		}

		.table__col--favorite {
			@media screen and (min-width: 429px) and (max-width: 575px) {
				display: none !important;
			}
		}

		&--match-upcoming {
			.table__col--result {

				@media screen and (max-width: 429px) {
					display: none !important;
				}
			}
		}

		&--match {
			height: 60px;
			border-bottom: 1px solid #ECEDED;
			cursor: pointer;
			background-color: $white;
			padding: 8px;
			display: flex;
			align-items: center;
			gap: 4px;
			grid-gap: 4px;
			justify-content: space-between;

			&:hover {
				background-color: #F6F6F6;

				.button--view-add {
					opacity: 1;
				}
			}
		}

		&--match-first {
			border-top: 1px solid #ECEDED;
		}

		&--match-last-l {
			border-bottom-left-radius: 6px;
			border-bottom-right-radius: 6px;
		}

		&--match-last {
			border-bottom: none;
		}
	}

	&__header-col {

		&--bet {
			width: 58px;
			height: 16px;
			text-transform: uppercase;
			color: rgba(23, 30, 33, 0.4);
			font-weight: 700;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 13px;
			transition: all 0.3s ease-out;
			opacity: 0.6;

			@media screen and (max-width: 992px) {
				width: 54px;
			}

			@media screen and (max-width: 428px) {
				display: none;
			}
		}
	}
}

.team-wrapper {
	min-width: 50px;
	display: flex;
	align-items: center;
	gap: 8px;
}

.team-ico {
	margin-right: 10px !important;

	&--card {}
}
// Firefox always shows alt for images, even while loading.
// Make alts transparent for Firefox
@-moz-document url-prefix() {
	.team-ico {
		color: transparent;
	}
}

.team-title {
	font-size: 13px;
	color: #171E21;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	font-weight: 400;
	line-height: 16px;

	@media screen and (max-width: 359px) {
		display: none;
	}

	&--strong {
		font-weight: 700;
	}

	&--gray {
		color: rgba(23, 30, 33, 0.4);
	}

	&--mobile {
		display: none;

		@media screen and (max-width: 359px) {
			display: block;
		}
	}
}



.jc {
	justify-content: space-between;
	display: flex;
	width: 100%;
}

.seo-text {
	margin-top: 24px;
	font-weight: 400;
	font-size: 11px;
	line-height: 14px;

	/* Text/Архив/Light Graphite */

	color: #88949A;

	@media screen and (max-width: $tablet) {
		padding-left: 8px;
		padding-right: 8px;
	}

}

.wrapper-card-ico {
	margin-left: 4px;
	width: 14px;
	height: 14px;
	display: flex;
	position: relative;
	cursor: pointer;

	&--margin {
		margin-right: -4px;
	}
}

.tooltip {
	display: none;
	align-items: center;
	left: -30px;
	top: 20px;
	padding-left: 10px;
	padding-right: 10px;
	position: absolute;
	/* First Shadow (Select) */
	min-height: 36px;
	outline: 2px solid rgba(255, 255, 255, 0.2);
	box-shadow: 0px 74px 30px rgb(0 0 0 / 1%), 0px 42px 25px rgb(0 0 0 / 3%), 0px 18px 18px rgb(0 0 0 / 4%), 0px 5px 10px rgb(0 0 0 / 5%), 0px 0px 0px rgb(0 0 0 / 5%);
	background-color: #ffffff;
	border-radius: 6px;
	z-index: 9;

	&--time {
		left: -10px;
	}

	&--kf {
		left: 50%;
		transform: translateX(-50%);
		top: 25px;
	}

	&__title {
		color: rgba(23, 30, 33, 0.6);
		white-space: nowrap;
	}

	&--odds {
		padding: 10px;
		gap: 10px;
		top: 34px;
		width: 240px;
		min-height: 68px;
		left: 50%;
		margin-left: -120px;
		/* BG/Fifth
		Hover / Stroke
		*/
		background: #282F3D;
		/* BG/White10% (архив) */

		border: 2px solid rgba(255, 255, 255, 0.1);
		/* First Shadow (Select) */

		box-shadow: $dropdown-shadow;
		border-radius: 6px;
		display: none;

		p {
			font-weight: 400;
			font-size: 13px;
			line-height: 16px;
			/* or 123% */
			/* Text/White/White */
			color: #FFFFFF;

		}
	}

}

.wrapper-card-ico:hover .tooltip,
.table__col--time:hover .tooltip {
	display: flex;
}

.bookmaker-badge {
	border-radius: 6px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 80px;
	height: 30px;

	img {
		width: 100%;
		height: 100%;
		max-width: 60px;
		max-height: 20px;
	}
}

.footer {
	width: 100%;
	padding: 32px 16px 16px;
	margin-top: 64px;
	background: linear-gradient(156.57deg, #45526D -58.71%, #252E3F 87.01%);

	@media screen and (max-width: $tablet) {
		margin-top: 0;
	}
	@media screen and (max-width: 768px) {
		margin-top: 0;
	}

	&__wr {
		@media screen and (max-width: 510px) {
			flex-direction: column;
		}
	}

	&__list-bottom {
		margin-top: 16px;
	}

	&__mobile {
		height: 14px;
		display: none;
		justify-content: center;
		margin-top: 16px;

		align-items: center;

		@media screen and (max-width: 1050px) {
			display: flex;
		}
	}

	&__bottom {
		margin-top: 64px;
		display: flex;
		justify-content: space-between;

		@media screen and (max-width: 786px) {
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 8px 0;
		}
	}

	&__title-bottom {
		font-weight: 700;
		font-size: 11px;
		line-height: 14px;
		/* identical to box height, or 127% */
		padding-top: 4px;
		text-align: center;
		text-transform: uppercase;

		/* Text/White/40% */

		color: rgba(255, 255, 255, 0.4);

		@media screen and (max-width: 1050px) {
			display: none;
		}

		&--main {
			display: block;
		}
	}

	&__title {
		color: $white;
		letter-spacing: 0.06em;
		font-weight: 700;
		font-size: 15px;
		line-height: 18px;
		text-transform: uppercase;
		margin-bottom: 16px;
	}
}

.col-4 {
	width: 25%;
}

.col-6-md {
	@media screen and (max-width: 1040px) {
		width: 50%;
	}

}

.col-12-xs {
	@media screen and (max-width: 510px) {
		width: 100%;
	}
}

.wrapper-logo-book {
	width: 80px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1;
}

.lg-footer-bottom {
	@media screen and (max-width: 992px) {
		display: none;
	}
}

.slick-active {
	.slider__dots {
		background-color: #FFDC21;
	}
}

.slick-dots {
	position: relative !important;
	bottom: 0 !important;
}

.slick-dots li {
	width: auto !important;
	height: auto !important;
	margin: 0 3px !important;
}

.slick-slide {
	padding-left: 12px;
	padding-right: 12px;
}

.slick-thumb {
	display: flex !important;
	align-items: center;
	justify-content: space-between;

	&--mobile {
		display: block !important;
	}
}

.slick-dots {
	height: 30px !important;
	padding-left: 12px !important;
	padding-right: 12px !important;
	margin-top: 8px !important;
}

.slider {

	&--one {
		.slick-slider {
			-webkit-user-select: auto;
			-moz-user-select: auto;
		}
	}

	&__left,
	&__right {
		width: 100%;
		justify-content: space-between;
		overflow: hidden;
	}

	&__center {
		margin-left: 10px;
		margin-right: 10px;
	}

	&__slide {
		border-radius: 6px;
		overflow: hidden;
	}

	&__arrow {
		width: 30px;
		height: 30px;
		border-radius: 6px;
		background-color: #394356;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		transition: background-color 0.3s ease-out;

		&--left {
			transform: rotate(-180deg);
		}

		&:hover {
			background-color: #282F3D;
		}
	}

	&__dots {
		background-color: #394356;

		width: 8px;
		height: 8px;
		border-radius: 50%;

		&--mobile {
			background-color: var(--bg-white-15, rgba(255, 255, 255, 0.15));
		}
	}

	&__ava {
		border-radius: 50%;
	}

	&__content-top {
		margin-top: 8px;
		justify-content: space-between;
	}

	&__slide-part {
		padding-top: 16px;
		padding-left: 12px;
		padding-right: 12px;
		padding-bottom: 8px;

		&--top {
			background-image: url('../public/img/bg-forecast.svg'), linear-gradient(10.71deg, #5E6C88 -9.43%, #252E3F 88.52%);
			background-size: cover;
		}

		&--bottom {
			background-color: #394356;
			padding-bottom: 32px;

			&--full {
				padding-bottom: 16px;
			}
		}
	}

	&__text {
		font-size: 13px;
		font-weight: 700;
		line-height: 16px;
		color: $white;

		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;

		&--left {
			padding-left: 10px;
		}

		&--right {
			padding-right: 10px;
		}
	}

	&__time {
		font-weight: 400;
		color: rgba(255, 255, 255, 0.4);
		font-size: 13px;
		line-height: 16px;
		text-align: center;
	}

	&__forecast-text-wrapper {
		position: relative;

		&:after {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			background: linear-gradient(0deg, #394356 0%, rgba(57, 67, 86, 0) 84.72%);
			height: 63px;
		}

		&--full {

			&:after {
				display: none;
			}

			.slider__text-forecast {
				overflow: visible;
				height: auto;
			}
		}
	}


	&__text-forecast {
		font-size: 13px;
		line-height: 16px;
		color: rgba(255, 255, 255, 0.6);
		height: 63px;
		overflow: hidden;
	}
	&__title-forecast {
		font-size: 15px !important;
	}
	&__text-paragraph {
		color: #88949a;
		font-size: 14px;
		line-height: 22px;
	}

	&--pl12 {
		padding-left: 12px;
	}
}



.block {
	&--full {
		.rating-list__col-header--last {
			display: flex;

			@media screen and (max-width: 458px) {
				display: none;
			}
		}
	}

	&--match-day {
		width: 100%;
		background: linear-gradient(10.71deg, #5E6C88 -9.43%, #252E3F 88.52%);
		border-radius: 6px;
		//padding: 8px 12px 18px;
		position: relative;
		overflow: hidden;

		&--expanded {
			.block--match-day__bg {
				height: auto;
			}
		}

		&--full {
			padding-bottom: 12px;

			.block--match-day__bg {
				height: auto;
			}

			.match-day-header {
				padding: 8px 12px;
			}
		}

		&--finished {
			position: relative;
			height: 38px;
			justify-content: space-between;
			padding: 4px 12px;
			display: flex;
			align-items: center;
		}
	}

	&--surebet {
		height: auto;
		position: relative;
		padding-bottom: 16px;
		padding-top: 16px;
		transition: all 0.3s ease-out;
	}

	&--surebet-match {
		.match-day {
			padding-top: 0;
		}
	}

	&--surebet-expanded {
		padding-bottom: 32px;
	}

	&--extra-padding {
		margin-bottom: 80px !important;
	}
}

.dropdown {
	position: relative;

	&--with-abs-list {
		position: static;
	}

	&__list-wrapper {
		position: relative;
	}

	&__sub-list {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		width: 65px;
		padding: 8px;
		outline: 2px solid rgba(255, 255, 255, 0.2);
		box-shadow: $dropdown-shadow;
		background-color: $white;
		border-radius: 4px;

	}

	&__list {
		display: none;
		position: absolute;
		border-radius: 4px;
		padding: 8px;
		z-index: 9998;
		left: 0;
		transform: translate3d(0, 5px, 0);
		height: 200px;
		width: 100%;
		outline: 2px solid rgba(255, 255, 255, 0.2);
		box-shadow: $dropdown-shadow;
		background-color: #ffffff;

		&--open {
			display: block;
		}
	}

	&__list-item {
		height: 40px;
		display: flex;
		align-items: center;
		color: #171E21;
		cursor: pointer;
		padding-left: 8px;
		padding-right: 8px;
		border-radius: 6px;
		font-size: 13px;

		&:hover {
			background: #ECEDED;
		}
	}
}

.dropdown-matches {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;
	height: 30px;
	padding-left: 8px;
	padding-right: 8px;
	background: rgba(255, 255, 255, 0.05);
	border-radius: 6px;
	width: 182px;
	cursor: pointer;
	justify-content: space-between;

	&--disabled {
		opacity: 0.4;
		background: rgba(255, 255, 255, 0.05);
		cursor: default;
	}

	&--odds {
		width: 100%;

		.dropdown-matches {
			height: 40px;
		}
	}

	&--competition {
		width: auto;
		min-width: 70px;

		.dropdown-matches {
			height: 40px;
			width: auto;
		}
	}

	&--competition-rounds {
		background-color: #ECEDED;
		font-size: 13px;
		border-radius: 6px;
		min-width: 110px;

		.dropdown-matches__title {
			letter-spacing: 0.26px;
			color: var(--text-black-40, rgba(23, 30, 33, 0.40));
			font-weight: 700;
			text-transform: uppercase;
		}
	}

	&--competition-rounds-active {
		background-color: #FFDC21;

		.dropdown-matches__title {
			color: var(--text-black-black, #171E21);
		}


		.dropdown-matches__ico {
			opacity: 1;
		}
	}

	@media screen and (max-width: 430px) {
		width: 152px;
	}

	&__title {
		color: $white;
		font-size: 13px;

		&--disabled {
			color: var(--text-white-40, rgba(255, 255, 255, 0.40));
		}
	}

	&__ico {
		opacity: 0.6;
		transform: rotate(180deg);
	}
}

.dropdown__header--active .dropdown-matches__ico {
	transform: rotate(0);
	opacity: 1;
}

.dropdown__header--active .dropdown-matches {
	background: rgba(255, 255, 255, 0.15);
}

.sports-list-dropdown {
	right: 0;
	width: 235px;
	height: 288px;
	left: auto;
	background: #434F65;

	&--user {
		width: 200px;

		.dropdown__list-item:hover {
			background-color: #1C222D !important;
		}
	}

	.dropdown__list-item {
		transition: background-color 0.3s ease-out;

		span {
			transition: all 0.3s ease-out;
			color: rgba(255, 255, 255, 0.6);
			font-size: 15px;
		}

		&:hover {
			background-color: #394356;

			span {
				color: $white;
			}
		}
	}
}

.forecast {
	width: 100%;
	scroll-margin-top: 120px;

	.slider {
		width: 100%;

		.slick-slide {
			padding-right: 0;
		}
	}

	&__bet-wrapper {
		background-color: #282F3D;
		height: 46px;
		width: 100%;
		border-radius: 6px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-left: 8px;
		padding-right: 8px;
	}

	&__bets {
		@media screen and (max-width: 700px) {
			flex-flow: column;
		}

		&--sidebar {
			flex-flow: column;
		}
	}

	&__bets-title {
		font-size: 13px;
		color: $white;
		font-weight: 700;
		letter-spacing: 0.3px;

		&--upper {
			text-transform: uppercase;
		}
	}
}

.accordion {
	&__item {

		&--active {
			.list__link-ico {
				transform: rotate(180deg);
			}
		}
	}

	&__content {
		overflow: hidden;
		transition: all 0.3s ease-out;
	}
}

@keyframes ph-animation {
	0% {
		transform: translate3d(-30%, 0, 0);
	}

	100% {
		transform: translate3d(30%, 0, 0);
	}
}

.penalty-wrapper {

	border-radius: 6px;
	background-color: #282F3D;
	min-height: 153px;


	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__row {
		padding-top: 8px;
		padding-left: 12px;
		padding-right: 12px;
		min-height: 76px;

		&--last {
			border-top: 1px solid rgba(255, 255, 255, 0.2);
		}

	}

	&__header-title {
		font-weight: 700;
		font-size: 13px;
		line-height: 16px;
		/* identical to box height, or 123% */
		/* Text/White/White */

		color: $white;
	}

	&__goal-wrapper {
		display: flex;
		align-items: center;
		grid-gap: 2px;
	}

	&__goal-circle {
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background-color: #0E8D5F;

		&--red {
			background-color: #FB4242;
		}
	}

	&__right {
		display: flex;
		align-items: center;
	}

	&__count-goal {
		padding-left: 8px;
		font-weight: 700;
		font-size: 13px;
		line-height: 16px;
		/* identical to box height, or 123% */

		font-family: Montserrat;
		text-align: center;

		/* Text/White/White */

		color: #FFFFFF;
	}

	&__content {
		display: flex;
		flex-wrap: wrap;
		grid-gap: 0 8px;
		padding-top: 8px;
		padding-bottom: 8px;
	}

	&__player {
		display: flex;
		align-items: center;
		grid-gap: 0 4px;
		height: 16px;
	}

	&__player-title {
		font-size: 13px;
		color: $white;

		&--goal {
			color: rgba(255, 255, 255, 0.4);
		}
	}
}

.match-day-header {
	position: relative;
	padding: 4px 12px;
}

.match-details {
	grid-gap: 3px;
	gap: 3px;
	display: grid;

	&__item {
		background-color: #394356;
		padding-left: 12px;
		padding-right: 12px;
		padding-top: 8px;
		padding-bottom: 8px;
		border-radius: 6px;
		display: flex;
		align-items: center;
	}

	&__title {
		font-style: normal;
		font-weight: 400;
		font-size: 13px;
		line-height: 16px;
		/* identical to box height, or 123% */
		/* Text/White/60% */
		color: rgba(255, 255, 255, 0.6);
	}

	&__subtitle {
		font-style: normal;
		font-weight: 700;
		font-size: 13px;
		line-height: 16px;
		/* identical to box height, or 123% */

		/* Text/White/White */

		color: #FFFFFF;
	}
}

small {
	font-size: smaller !important;
}

sup {
	font-size: xx-small !important;
	vertical-align: text-top !important
}

.sidebar--full> :nth-child(n+2) {
	max-width: 768px;
	margin-left: auto;
	margin-right: auto;
}

.wrapper-ico-video {
	@media screen and (max-width: 428px) {
		display: none;
	}
}

.full-width {
	width: 100%;
}

.arrow-fig {
	background: rgba(255, 255, 255, 0.6);
	border-radius: 0.5px;
	transform: matrix(1, 0, 0, -1, 0, 0);
	width: 6px;
	height: 6px;
}

.square-fig {
	width: 2px;
	height: 2px;

	background: #FFFFFF;
}

.score-switcher {
	display: flex;
	justify-content: space-around;
	margin-bottom: 16px;

	&__title {
		text-transform: uppercase;
		/* Text/White/60% */
		font-weight: 700;
		font-size: 13px;
		margin-bottom: 8px;
		color: rgba(255, 255, 255, 0.6);
		text-overflow: ellipsis;
		overflow: hidden;
		text-align: center;
		white-space: nowrap;
		max-width: 100px;
	}

	&__side {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}

	&__header {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__wrapper {
		display: flex;
		align-items: center;
	}

	&__score {
		width: 42px;
		height: 32px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-family: Montserrat;
		font-weight: 700;
		font-size: 28px;
		color: $white
	}
}

.handicap-wrapper {
	background: rgba(255, 255, 255, 0.2);
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 400;
	font-size: 13px;
	line-height: 16px;
	/* identical to box height, or 123% */
	padding: 4px;
	height: 24px;

	/* Text/White/White */
	//min-width: 40px;
	color: #FFFFFF;
}

.highlight {
	color: $white !important;
	opacity: 1 !important;
}

.overlay {
	position: fixed;
	padding-left: 15px;
	padding-right: 15px;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba(8, 13, 21, 0.6);
	align-items: center;
	justify-content: center;
	z-index: 9999;
	display: none;


	&--open {
		display: flex;
	}

	@media screen and (max-width: 768px) {
		padding-left: 0;
		padding-right: 0;
		top: 112px;
	}

	@media screen and (max-width: 675px) {
		top: 121px;
	}

}

.overflow-body {
	overflow: hidden;
}

.modal {
	width: 920px;
	background: #FFFFFF;
	border-radius: 4px;
	height: 474px;
	position: relative;


	&--auth {
		@media screen and (max-width: 768px) {
			width: 100%;
			height: 100% !important;
			border-radius: 0;
		}

		@media screen and (max-width: 675px) {
			overflow-y: scroll;
		}
	}

	&--auth-success {
		width: auto;
		height: auto;
		padding: 40px;

		@media screen and (max-width: 675px) {
			padding: 0;
			width: 100%;
		}
	}

	&__tooltip {
		height: 40px;

		background: #FFFFFF;
		border: 2px solid rgba(251, 66, 66, 0.2);
		/* First Shadow (Select) */

		box-shadow: $dropdown-shadow;
		border-radius: 6px;
		width: 300px;
		position: absolute;
		top: -20px;
		display: flex;
		align-items: center;
		justify-content: center;

		@media screen and (max-width: 675px) {
			position: fixed;
			top: auto;
			bottom: 16px;
			left: 15px;
			right: 30px;
			z-index: 9999;
			width: auto;
		}
	}

	&__tooltip-container {
		position: relative;
		display: flex;
		align-items: center;
		height: 100%;
		justify-content: center;
		width: 100%;
	}



	&__tooltip-text {
		white-space: nowrap;
		font-weight: 400;
		font-size: 13px;
		/* identical to box height, or 123% */

		text-align: center;

		/* Text/Black/Black */

		color: #171E21;
	}

	&__close {
		width: 24px;
		height: 24px;
		top: 16px;
		right: 16px;
		position: absolute;
	}

	&--signup {
		height: 544px;

	}

	&__logo {
		position: absolute;
		top: 40px;
	}

	&__content {
		display: flex;
		height: 100%;
	}

	&__title {
		font-weight: 700;
		font-size: 18px;
		line-height: 22px;
		/* or 122% */
		text-align: center;
		text-transform: uppercase;
		/* Text/White/White */
		color: #FFFFFF;
	}

	&__subtitle {
		margin-top: 8px;
		font-weight: 400;
		font-size: 15px;
		line-height: 18px;
		/* or 120% */
		text-align: center;
		/* Text/White/60% */
		color: rgba(255, 255, 255, 0.6);
	}

	&__left-side {
		background-image: url('../public/img/bg_pattern_auth_register.svg'), linear-gradient(10.71deg, #5E6C88 -9.43%, #252E3F 88.52%);
		background-size: cover;
		background-repeat: no-repeat;
		position: relative;
		justify-content: center;
		border-radius: 4px;
		align-items: center;
		overflow: hidden;

		@media screen and (max-width: 768px) {
			border-radius: 0;
		}

		@media screen and (max-width: 675px) {
			display: none;
		}

		&--signup {
			background-image: url('../public/img/bg_pattern_auth_register.svg'), linear-gradient(10.71deg, #5E6C88 -9.43%, #252E3F 88.52%);
		}
	}

	&__right-side {
		align-items: center;
		justify-content: center;
		padding-left: 40px;
		padding-right: 40px;

		@media screen and (max-width: 675px) {
			width: 100% !important;
			padding-left: 15px;
			padding-right: 15px;
		}

	}

	&__more-item {
		font-weight: 400;
		font-size: 13px;
		line-height: 16px;
		/* identical to box height, or 123% */

		text-align: center;

		/* Text/White/White */

		color: #FFFFFF;
	}

	&__tooltip-close {
		position: absolute;
		right: 5px;
		top: 5px;
		display: none;

		@media screen and (max-width: 675px) {
			display: block;
		}

	}
}

.half {
	width: 50%;
	height: 100%;
}

.features {
	display: grid;
	flex-wrap: wrap;
	grid-gap: 32px 8px;
	grid-template-columns: 2fr 2fr;
	margin-top: 32px;
	margin-bottom: 32px;

	&__item {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&__circle {
		width: 60px;
		height: 60px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(255, 255, 255, 0.1);
		border-radius: 50%;

		&--mobile {
			height: 48px;
			width: 48px;
		}
	}

	&__title {
		font-weight: 700;
		font-size: 13px;
		text-align: center;
		line-height: 16px;
		/* identical to box height, or 123% */
		/* Text/White/White */
		color: #FFFFFF;
	}
}

.form-block {
	display: flex;
	flex-direction: column;
	width: 100%;

	@media screen and (max-width: 675px) {
		height: 100%;
	}

	&__link-already {
		text-decoration: underline;
		cursor: pointer;
	}

	&__columns {
		grid-template-columns: 1fr 1fr;

		@media screen and (max-width: 768px) {
			grid-template-columns: 1fr;
		}
	}

	&__already {
		font-weight: 400;
		font-size: 11px;
		line-height: 14px;
		/* identical to box height, or 127% */
		margin-top: 16px;
		text-align: center;

		/* Text/Black/60% */

		color: rgba(23, 30, 33, 0.6);
	}

	&__title {
		font-weight: 700;
		font-size: 18px;
		line-height: 22px;
		/* identical to box height, or 122% */

		margin-bottom: 24px;
		/* Text/Black/Black */

		color: #171E21;
	}

	&__desc {
		font-weight: 400;
		font-size: 11px;
		line-height: 14px;
		/* or 127% */

		text-align: center;
		margin-top: 16px;
		/* Text/Black/60% */
		color: rgba(23, 30, 33, 0.6);

		a {
			text-decoration: underline;
		}
	}
}

.form {

	&--signin {}

	&__button-wrapper {
		display: grid;
		grid-auto-flow: column;
		grid-gap: 8px;
	}

	&__subtitle {
		font-size: 13px;
		line-height: 16px;
		/* identical to box height, or 123% */

		display: flex;
		align-items: center;

		/* Text/Black/Black */

		color: #171E21;

		&--forgot {
			cursor: pointer;
			font-weight: 400;
			font-size: 13px;
			line-height: 16px;
			/* identical to box height, or 123% */

			display: flex;
			align-items: center;
			text-align: right;
			text-decoration-line: underline;

			/* Text/Black/60% */

			color: rgba(23, 30, 33, 0.6) !important;

		}
	}

	&__input-wrapper {
		position: relative;

		&--error {
			.form__subtitle {
				color: $primary-red-color;
			}

			.form__input {
				border: 1px solid $primary-red-color;
				outline: $transparent-color !important;
			}

			.form__input:focus {
				border: 1px solid $primary-red-color;
				outline: $transparent-color !important;
			}
		}
	}

	&__text {
		color: #171E21;
	}

	&__input {
		border: 1px solid #F0F0F2;
		border-radius: 6px;
		height: 40px;
		width: 100%;
		padding-left: 8px;
		font-weight: 400;
		font-size: 13px;
		line-height: 16px;
		/* identical to box height, or 123% */
		transition: 0.3s ease-out color;
		display: flex;
		align-items: center;
		outline: transparent;
		/* Text/Black/Black */
		font-family: "PT Sans", sans-serif !important;
		color: #171E21;

		&:focus {
			border: 1px solid #FFC700;
			// -webkit-box-shadow: 0px 0px 2px 1px #ffdc2133;
			// -moz-box-shadow: 0px 0px 2px 1px #ffdc2133;
			// box-shadow: 0px 0px 2px 1px #ffdc2133;
			outline: 2px solid rgba(255, 220, 33, 0.2);
		}

		&::placeholder {
			font-size: 13px;
			color: rgba(23, 30, 33, 0.4);
		}

		&--password {
			letter-spacing: 0.2em;
		}
	}
}

.dropdown-auth {
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;

	.dropdown-matches__ico {
		transform: rotate(0deg);
		opacity: 1;
	}

	.dropdown__list {
		top: 40px;
	}

	.dropdown__header {
		position: relative;
		height: 40px;
		width: 100%;
		border: 1px solid #F0F0F2;
		border-radius: 6px;
		padding-left: 8px;
		padding-right: 8px;
	}

	&__title {
		font-weight: 400;
		font-size: 13px;
		/* identical to box height, or 123% */

		display: flex;
		align-items: center;

		/* Text/Black/Black */

		color: #171E21;
	}

	.dropdown__header--active {
		.dropdown-matches__ico {
			transform: rotate(180deg) !important;
		}
	}
}

.react-calendar__tile--now.react-calendar__month-view__days__day {
	font-weight: 700 !important;
	position: relative;

	&::after {
		content: '';
		position: absolute;
		left: 8px;
		bottom: 4px;
		height: 2px;
		width: 15px;
		background-color: $primary-color;
	}
}

.react-calendar__tile:disabled {
	background-color: transparent !important;
	color: rgba(23, 30, 33, 0.2);
}

.no-content {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	grid-gap: 8px;
	gap: 8px;
	padding: 16px;
	width: 100%;
	background-color: #394356;
	border-radius: 6px;

	&__title {
		font-weight: 400;
		font-size: 13px;
		line-height: 16px;
		/* identical to box height, or 127% */
		text-align: center;

		/* Text/White/40% */

		color: rgba(255, 255, 255, 0.4);
	}

	&--forecast {
		flex-direction: row;
		background-color: #434F65;
		text-transform: uppercase;
		height: 46px;
		margin-top: 32px;


		.no-content__title {
			font-size: 11px;
		}
	}
}

.tooltip-hover {

	.rating-list__td--sub .match-events__tooltip {
		left: -30px;
	}

	&:hover {
		.rating-list__td--sub .match-events__tooltip {
			display: block;
		}
	}
}

.field {

	position: relative;
	background-image: url("../public/img/field.svg");
	background-size: cover;
	background-repeat: no-repeat;

	&__player-title-tooltip {
		color: $white;
		font-size: 13px;
		font-style: normal;
		font-weight: 700;
		line-height: 16px;
		/* 123.077% */
	}

	&__player-subtitle-tooltip {
		color: var(--text-white-60, rgba(255, 255, 255, 0.60));
		/* 13/Text 13 */
		font-size: 13px;
		font-style: normal;
		font-weight: 400;
		line-height: 16px;
		/* 123.077% */
	}


	&--vertical {
		background-image: url("../public/img/field-vertical.svg");

		.field__wrapper {
			flex-direction: column;
		}
	}

	&__wrapper {
		width: 100%;
		height: 370px;
		border-radius: 6px;
		position: relative;

	}



	&__player {
		position: absolute;
		height: 56px;

		&:hover {
			.tooltip {
				display: flex;
			}
		}
	}

	&__player-label {
		border-radius: 6px;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		height: 22px;
		display: flex;
		justify-content: center;
		align-items: center;
		padding-left: 4px;
		padding-right: 4px;
		color: $white;
		background: rgba(0, 0, 0, 0.4);
		z-index: 1;
		bottom: 0;
		white-space: nowrap;
		background-position: center;
		// backdrop-filter: blur(8px);
		background-image: url("../public/img/blured.png");
		max-width: 74px;
		text-overflow: ellipsis;
		overflow: hidden;

		span {
			font-size: 11px;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			font-weight: 700;
			text-transform: uppercase;
		}

		&--team1 {
			background-image: url("../public/img/blured2.png");
		}

	}

	&__player-tshirt {
		width: 47px;
		height: 47px;
		background-image: url("../public/img/player-tshirt.svg");
		display: flex;
		justify-content: center;
		padding-top: 9px;

		span {
			font-weight: 700;
			font-size: 13px;
			color: $white;
		}

		&--yellow {
			background-image: url("../public/img/player-tshirt-yellow.svg");
		}

		&--black {
			span {
				color: black;
			}

		}
	}
}

.signin-block-mobile {
	border-radius: 6px;
	padding: 15px;
	grid-gap: 8px;
	gap: 8px;
	display: none;
	background-color: #434F65;
	flex-direction: column;
	margin-bottom: 40px;
	margin-top: 80px;
	background-image: url("../public/img/bg-sig.svg");
	background-repeat: no-repeat;
	background-size: cover;

	&--register {
		margin-top: 60px;
		text-align: center;
	}

	@media screen and (max-width: 675px) {
		display: flex;
	}

	&__title {
		font-family: PT Sans;
		color: $white;
		font-size: 18px;
		font-style: normal;
		font-weight: 700;
		line-height: 22px;
		/* 122.222% */
	}

	&__subtitle {
		color: $white;
		/* 13/Text 13 */
		font-family: PT Sans;
		font-size: 13px;
		font-style: normal;
		font-weight: 700;
		line-height: 16px;
		/* 123.077% */
	}

	&__slide {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 8px;
		grid-gap: 8px;

	}

	.slick-dots {
		height: auto !important;
	}

	.slick-slide {
		padding-left: 0;
		padding-right: 0;
	}
}

.success-register {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	width: 100%;

	&__circle {
		border-radius: 50%;
		border: 7px solid var(--main-light-yellow, #FFF8D2);
		background: var(--main-white, #FFF);
		width: 50px;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__title {
		color: var(--bg-third, #434F65);
		text-align: center;
		/* 15/Text 15 */
		font-family: PT Sans;
		font-size: 15px;
		font-style: normal;
		font-weight: 700;
		line-height: 18px;
		/* 120% */
	}

	&__content {
		display: flex;
		text-align: center;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		grid-gap: 24px;
	}
}

.series {
	display: flex;
	grid-gap: 4px;
	gap: 4px;
	flex-direction: column;
	padding-left: 12px;
	padding-right: 12px;
	align-items: center;
	width: 100%;

	&__item-wrapper {
		display: flex;
		flex-direction: row;
		width: 100%;
		grid-gap: 8px;
		gap: 8px;
		align-items: center;
		position: relative;
	}

	&__bookmaker-title {
		/* 13/Text 13 CAPS */
		font-family: PT Sans;
		font-size: 13px;
		font-style: normal;
		font-weight: 700;
		line-height: 16px;
		/* 123.077% */
		text-transform: uppercase;
		align-self: flex-start;

		&--first {
			margin-top: 24px;
			margin-bottom: 4px;
		}

	}

	&__expand {
		width: 68px;
		height: 68px;
		position: absolute;
		top: 50%;
		margin-top: -34px;
		right: -10px;
		padding-left: 30px;

		img {
			transition: all 0.3s ease-out;
		}

		&--active img {
			transform-origin: center center;
			transform: rotate(180deg);
		}
	}

	&__item {
		position: relative;
		display: flex;
		flex-direction: column;
		width: 100%;
		align-items: center;
		border-radius: 6px;
		background: var(--bg-fourth, #394356);
		overflow: hidden;
		padding: 16px 12px;

		&--animated {
			transition: all 0.3s ease-in-out;
		}
	}

	&__bets-wrapper {
		overflow: hidden;
		transition: all 0.3s ease-in-out;
	}

	&__title {
		color: var(--text-white-white, #FFF);
		font-family: PT Sans;
		font-size: 13px;
		font-style: normal;
		font-weight: 700;
		line-height: 16px;
		text-overflow: ellipsis;
		overflow: hidden;
		/* 120% */
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		/* Количество строк */
		max-width: 276px;
	}

	&__subtitle {
		color: var(--text-white-white, #FFF);
		font-family: PT Sans;
		font-size: 13px;
		font-style: normal;
		line-height: 16px;

		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		/* Количество строк */
		/* 120% */
	}
}

.sidebar--full .series__title {
	max-width: max-content;
	width: 100%;
}

.field--vertical {
	.rating-list__team-wrapper--field-right {
		left: 8px;
		bottom: 8px;
		top: auto;
	}

	.rating-list__formation--left {
		right: 12px;
		top: 12px;
		left: auto;
		bottom: auto;
	}
}

.table__header-col--bet {
	opacity: 0;
}

.table__header--expanded .table__header-col--bet {
	opacity: 1;
}

.react-calendar__navigation__label__labelText {
	font-size: 13px !important;
}

.sidebar--full {

	.menu--sidebar {
		justify-content: center;

		@media screen and (max-width: 500px) {
			justify-content: flex-start;
		}
	}

	.wrapper-match-info {
		width: 1028px;
		max-width: unset;
		display: flex;
		justify-content: center;

		$page-padding: 30px;
		$sidebar-width: 240px;
		$sidebar-gap: 12px;

		@media screen and (max-width: 1310px) {
			width: calc(100vw - $page-padding - $sidebar-width - $sidebar-gap);
		}
		@media screen and (max-width: 1294px) {
			$sidebar-width: 208px;
			width: calc(100vw - $page-padding - $sidebar-width - $sidebar-gap);
		}
		@media screen and (max-width: 860px) {
			$sidebar-width: 208px;
			width: calc(100vw - $page-padding);
		}
		@media screen and (max-width: 768px) {
			width: 100%;
		}
	}

	.two-series__content--info {
		margin-left: 0;
	}

	.block-controll {
		position: absolute;
		right: 12px;
	}

	.match-info {
		width: 100%;
		display: flex;
		justify-content: center;

		@media screen and (max-width: 429px) {
			justify-content: flex-start;
			padding-left: 55px;
		}
		@media screen and (max-width: 360px) {
			padding-left: 50px;
		}
	}
}

.menu-sidebar {
	height: 50px;
	width: 100%;
	max-width: 100vw !important;
	transition: all 0.3s ease-in-out;
	position: sticky;
	z-index: 8000;
	top: 61px;
	background: #434F65;

	&::before {
		content: '';
		opacity: 0;
		background: linear-gradient(156.57deg, #45526d -58.71%, #252e3f 87.01%);
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		position: absolute;
		z-index: 0;
		transition: all 0.3s ease-in-out;
	}

	&--bg {
		&::before {
			content: '';
			opacity: 1;
		}
	}

	&::after {
		content: '';
		position: absolute;
		width: 130px;
		top: 0;
		right: 0;
		bottom: 0;

		pointer-events: none;
		opacity: 0;
		transition: opacity .2s ease-in;
		background: linear-gradient(270deg, #2F394E 8.74%, rgba(44, 54, 73, 0.00) 100%);
	}
	&--text-fadeout::after {
		opacity: 1;
	}
}

.team-avatar-placeholder {
	width: 68px;
	height: 68px;
	border-radius: 50%;
	background-color: #232A38;
	display: flex;
	align-items: center;
	justify-content: center;
}

.header__menu-tools-user .dropdown__list .button__ico-filter {
	margin-right: 8px !important;
}

.subheader {
	padding-left: 8px;
	padding-right: 8px;

	background: #ECEDED;
	height: 0;
	transition: all 0.3s ease-in;
	overflow: hidden;

	.table__header-col--bet {
		opacity: 1;
		display: flex;
		font-weight: 700;
		transition: all 0.3s ease-in;
	}

	&--expanded {
		@media screen and (max-width: $tablet) {
			height: 24px;
		}

		.table__header-col--bet {
			opacity: 1;
			font-weight: 700;
		}
	}

	&__container {
		border-top: 1px solid rgba(0, 0, 0, 0.10);
		height: 24px;
		justify-content: flex-end;


	}
}

.table__block--result:hover {
	position: relative;

	.tooltip {
		display: flex;
	}
}

.mask-container {
	position: relative;
	width: 100%;
	height: 16px;
	overflow: hidden;
	white-space: nowrap;
	margin-right: 20px;

	//&::after {
	//	content: '';
	//	position: absolute;
	//	z-index: 2;
	//
	//	height: 100%;
	//	width: 20px;
	//	right: 0;
	//
	//	background: linear-gradient(270deg, #F0F0F2 10.53%, rgba(240, 240, 242, 0.00) 100%);
	//}
	//
	//&::before {
	//	content: '';
	//	position: absolute;
	//	z-index: 2;
	//
	//	height: 100%;
	//	width: 20px;
	//	left: 0;
	//
	//	background: linear-gradient(90deg, #F0F0F2 10.53%, rgba(240, 240, 242, 0.00) 100%);
	//}
}

.mask {
	content: '';
	/* Создаем псевдоэлемент */
	position: absolute;
	/* Абсолютное позиционирование */
	top: 0;
	left: 0;
	width: 100%;
	/* Занимает всю доступную ширину */
	height: 100%;
	/* Занимает всю доступную высоту */
	/* Цвет фона "маски", можно установить цвет фона родительского контейнера */
	z-index: 2;
	/* Размещаем псевдоэлемент выше вложенного контейнера */
}

.no-bets-title {
	position: absolute;
	right: 0;
	left: 32px;
	text-align: center;
	color: rgba(23, 30, 33, 0.40);
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;
	/* 123.077% */

	@media screen and (max-width: 428px) {
		left: 4px;
	}
}

.match-events__tooltip--matchday {
	top: 34px;
	height: 36px;
	align-items: center;

	.match-events__tooltip-text {
		color: $white;
		font-size: 13px;
		font-style: normal;
		font-weight: 400;
		line-height: 16px;
		/* 123.077% */
	}
}

.forecast-result {
	border-radius: 6px;
	background: var(--bg-fifth, #232A38);
	padding-left: 16px;
	padding-right: 16px;
	display: flex;
	height: 46px;
	align-items: center;
	justify-content: space-between;
	width: 100%;

	&__title {
		color: var(--main-white, #FFF);
		text-align: right;
		/* 11 CAPS */
		font-size: 11px;
		font-style: normal;
		font-weight: 700;
		line-height: 14px;
		/* 127.273% */
		text-transform: uppercase;
	}

	&__bet {
		display: flex;
		height: 30px;
		justify-content: center;
		align-items: center;
		border-radius: 6px;
		padding: 6px 8px;
		color: var(--text-white-white, #FFF);
		text-align: right;
		/* 13/Text 13 CAPS */
		font-size: 13px;
		font-style: normal;
		font-weight: 700;
		line-height: 16px;
		/* 123.077% */
		text-transform: uppercase;

		&--win {
			background: #0E8D5F;
		}

		&--lose {
			background: #FF4848;
		}

		&--back {
			background: #338AF3;
		}
	}
}

.match-info {
	border-bottom: 1px solid var(--bg-third, #434F65);
	display: flex;

	z-index: 9998;

	align-items: center;
	height: 62px;
	padding-left: 12px;
	padding-right: 12px;
	justify-content: space-between;
	background: linear-gradient(156.57deg, #45526d -58.71%, #252e3f 87.01%);
	transform: translate3d(0, -62px, 0);
	transition: all 0.3s ease-in-out;

	&--open {
		transform: translate3d(0, 0, 0);
	}
}

.wrapper-match-info {
	$root: &;

	height: 0;
	top: 0;
	z-index: 9998;
	overflow: hidden;
	position: fixed;
	transition: all 0.3s ease-in-out;

	&--open {
		height: 62px;
	}

	&--sidebar-narrow {
		width: 394px;

		@media screen and (max-width: 1190px) {
			width: 346px;
		}

		&#{$root}--live {
			.match-info {
				padding-left: 82px;
			}
		}
	}
}

.main-logo {
	width: 208px;
	height: 32px;

	&--redirect {
		background-image: url("../public/img/logo_new.svg");
		width: 208px;
		height: 32px;
		background-image: url("../public/img/logo_new.svg");
		background-repeat: no-repeat;
		overflow: hidden;

		@media screen and (max-width: 360px) {
			width: 162px;
			height: 32px;

		}
	}

	@media screen and (max-width: 360px) {
		width: 162px;
		height: 32px;
	}
}

.redirect-page {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #2D374A;
	height: 100vh;
	overflow: hidden;
}

.redirect-block {
	border-radius: 12px;
	background: var(--bg-sixth, #1C222D);
	padding: 32px;
	gap: 32px;
	display: flex;
	flex-direction: column;
	align-items: center;

	@media screen and (max-width: 469px) {
		width: 100%;
		height: 100vh;
		padding: 17px;
		border-radius: 0;
		justify-content: center;
	}

	&__inner {
		padding: 17px;
		border-radius: 12px;
		//background: #EE7133;
		gap: 8px;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;


	}

	&__bookmaker {
		width: 120px;
		height: 45px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__title {
		color: $white;
		text-align: center;
		/* 15/Text 15 */
		font-size: 15px;
		font-style: normal;
		font-weight: 400;
		line-height: 18px;
		/* 120% */
	}
}

.league-header {
	height: 102px;
	padding-top: 12px;
	padding-left: 12px;
	padding-right: 12px;
	background-color: #171E21;
	width: 100%;
	border-radius: 6px;
	display: flex;
	background-image: url('../public/img/bg-comp.svg');

	&--hidden {
		display: none;
	}

	&__col-d {
		height: 40px;

		@media screen and (max-width: 768px) {
			display: flex;
			justify-content: center;
		}
	}

	&__top-list {
		@media screen and (max-width: 768px) {
			align-items: center;
			display: flex;
			height: 72px;
			padding-left: 12px;
			padding-right: 12px;
		}
	}

	@media screen and (max-width: 768px) {
		background-image: none;
		background: var(--gradient-black, linear-gradient(157deg, #45526D -58.71%, #252E3F 87.01%));
		border-radius: 0;
		margin-top: 12px;
		margin-bottom: 0;
		padding-left: 0;
		grid-gap: 0;
		padding-right: 0;
		padding-top: 0;
		height: 122px;
	}

	&__title {
		color: var(--main-white, #FFF);
		/* H3/H3 CAPS L6 */
		font-size: 15px;
		font-style: normal;
		font-weight: 700;
		line-height: 18px;
		/* 120% */
		letter-spacing: 0.9px;
		text-transform: uppercase;
	}

	&__country {
		display: flex;
		width: 30px;
		height: 30px;
		justify-content: center;
		align-items: center;
		border-radius: 30px;
		border: 1px solid rgba(240, 240, 242, 0.20);
	}

	&__subtitle {
		color: var(--main-white, #FFF);
		/* 13/Text 13 CAPS */
		font-size: 13px;
		font-style: normal;
		font-weight: 700;
		line-height: 16px;
		/* 123.077% */
		text-transform: uppercase;
		opacity: 0.4;
		margin-top: 2px;
	}

	&__image {
		display: flex;
		width: 78px;
		height: 78px;
		justify-content: center;
		align-items: center;
		flex-shrink: 0;
		border-radius: 6px;
		background: rgba(255, 255, 255, 0.10);

		@media screen and (max-width: 768px) {
			display: none;
		}
	}
}

.wrapper-header-match {
	margin-top: 8px;
	/* outline: 1px solid #ECEDED; */
	border-left: 1px solid #eceded;
	border-right: 1px solid #eceded;
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;

	@media screen and (max-width: 768px) {
		margin-top: 0;
	}

	&--no {
		margin-top: 0;
	}
}

.matches-not-found {

	height: 144px;
	margin-top: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 6px;
	background: var(--container-ten-main, #F5F5F5);

	&__title {
		color: var(--text-black-60, rgba(23, 30, 33, 0.60));
		text-align: center;
		/* 13/Text 13 */
		font-family: PT Sans;
		font-size: 13px;
		font-style: normal;
		font-weight: 400;
		line-height: 16px;
		/* 123.077% */
	}
}

.loader {
	width: 16px;
	height: 16px;
	display: none;
	opacity: 0;
	visibility: hidden;
	position: relative;

	&--active {
		visibility: visible;
		opacity: 1;
		display: block;
	}

	&__inner {
		position: absolute;
		top: 0;
		left: 0;
	}

	&__outer {
		position: absolute;
		top: 0;
		left: 0;
		animation: rotate 1s linear infinite;
	}
}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.best-offers {
	overflow: hidden;
	position: absolute;
	left: 0;
	top: 0;
	border-radius: 6px;
	outline: 2px solid rgba(255, 255, 255, 0.20);
	width: 240px;
	background: #FFF;
	z-index: 9999;
	/* First Shadow (Select) */
	box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.05), 0px 5px 10px 0px rgba(0, 0, 0, 0.05), 0px 18px 18px 0px rgba(0, 0, 0, 0.04), 0px 42px 25px 0px rgba(0, 0, 0, 0.03), 0px 74px 30px 0px rgba(0, 0, 0, 0.01), 0px 116px 32px 0px rgba(0, 0, 0, 0.00);

	&--mobile {
		position: relative;

		outline: none;
		border-radius: 6px 6px 0 0;
	}

	&__img-hovered {
		opacity: 0;
		position: absolute;
		transition: all 0.3s ease-in-out;
	}

	&__header {
		background: var(--container-ten-main, #F5F5F5);
		height: 40px;
		padding-left: 12px;
		padding-right: 12px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__title {
		color: var(--text-black-black, #171E21);
		/* 13/Text 13 CAPS */
		font-family: PT Sans;
		font-size: 13px;
		font-style: normal;
		font-weight: 700;
		line-height: 16px;
		/* 123.077% */
		text-transform: uppercase;

		&--footer {
			color: $white;
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		gap: 4px;
		padding: 8px 12px;
		min-width: 240px;
		min-height: 160px;
	}

	&__item {
		border-radius: 8px;
		background: #F5F5F5;
		width: 100%;
		height: 46px;
		position: relative;
		cursor: pointer;
		transition: all 0.3s ease-in-out;

		&--virtual {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 8px;
			position: relative;

			img {
				filter: brightness(0) saturate(100%) invert(22%) sepia(26%) saturate(580%) hue-rotate(180deg) brightness(94%) contrast(85%);
				-webkit-filter: brightness(0) saturate(100%) invert(22%) sepia(26%) saturate(580%) hue-rotate(180deg) brightness(94%) contrast(85%);
				animation: imgBlack .2s forwards;
			}

			&:hover {
				background-color: $green;
				img {
					animation: imgColor .3s forwards;
				}
			}

			@keyframes imgColor {
				from {
					filter: brightness(0) saturate(100%) invert(22%) sepia(26%) saturate(580%) hue-rotate(180deg) brightness(94%) contrast(85%);
					-webkit-filter: brightness(0) saturate(100%) invert(22%) sepia(26%) saturate(580%) hue-rotate(0deg) brightness(94%) contrast(85%);
				}
				to {
					filter: brightness(1) saturate(100%) invert(0%) sepia(0%) saturate(100%) hue-rotate(0deg);
					-webkit-filter: brightness(1) saturate(100%) invert(0%) sepia(0%) saturate(100%) hue-rotate(0deg);
				}
			}
			@keyframes imgBlack {
				from {
					filter: brightness(1) saturate(100%) invert(0%) sepia(0%) saturate(100%) hue-rotate(0deg);
					-webkit-filter: brightness(1) saturate(100%) invert(0%) sepia(0%) saturate(100%) hue-rotate(0deg);
				}
				to {
					filter: brightness(0) saturate(100%) invert(22%) sepia(26%) saturate(580%) hue-rotate(180deg) brightness(94%) contrast(85%);
					-webkit-filter: brightness(0) saturate(100%) invert(22%) sepia(26%) saturate(580%) hue-rotate(180deg) brightness(94%) contrast(85%);
				}
			}
		}

		&:hover {
			.best-offers__bet {
				background-color: #fff;
				border-color: #fff;

			}

			.best-offers__bet--virtual {
				border: 1px solid rgba(255, 255, 255, 0.60);
				background: transparent;
			}

			.best-offers__item-overlay {
				opacity: 1;
			}

			.best-offers__img-hovered {
				opacity: 1;
			}
		}
	}

	&__item-content {
		z-index: 1;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 8px 8px 8px 13px;
		position: relative;
	}
	&__item-star {
		position: absolute;
		width: 16px;
		height: 16px;

		left: 0;
		top: 50%;
		transform: translate(-50%, -50%);

		background-color: $white;
		border-radius: 50%;
	}

	&__item-overlay {
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		z-index: 0;
		opacity: 0;
		transition: all 0.3s ease-in-out;
		border-radius: inherit;
	}

	&__bet {
		border-radius: 6px;
		border: 1px solid var(--text-black-20, rgba(23, 30, 33, 0.20));
		display: flex;
		width: 58px;
		height: 30px;
		align-items: center;
		justify-content: center;
		transition: all 0.3s ease-in-out;
	}

	&__text {
		color: var(--text-black-black, #171E21);
		text-align: center;
		/* 13/Text 13 */
		font-family: PT Sans;
		font-size: 13px;
		font-style: normal;
		font-weight: 700;
		line-height: 16px;
	}

	&__footer-top {
		display: flex;
		align-items: center;
		height: 40px;
		padding-left: 16px;
		padding-right: 16px;
		background-color: $green;
	}

	&__footer-bottom {
		padding: 8px 12px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.blocked-country {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 16px;
	color: white;
	font-size: 18px;
	line-height: 22px;
	background: rgba(42, 52, 70, 1);
	height: 100vh;
	padding: 0 12px;
	text-align: center;

	p {
		display: block;
	}

	img {
		width: 416px;

		@media (max-width: 760px) {
			width: 80%;
		}
	}
}

.bonus-text {
	text-transform: uppercase;
	text-overflow: ellipsis;
	text-wrap: nowrap;
	overflow: hidden;
	font-size: 14px;
	z-index: 1;
}

.competition-match-row__last-row .table__row {
	border-radius: 0 0 6px 6px;
	border-bottom: 1px solid #ECEDED;
}