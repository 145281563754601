.match-day {
  position: relative;
  text-align: center;
  padding: 24px 12px 16px 12px;
  background-image: url('../public/img/bg_pattern.svg');

  &-header__menu {
    justify-content: flex-end;
  }

  &__date {
    color: var(--text-white-40, rgba(255, 255, 255, 0.40));
    text-align: center;
    /* 11 CAPS */
    font-family: PT Sans;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    /* 127.273% */
    text-transform: uppercase;
    margin-top: -16px;
    margin-bottom: 24px;
  }

  &--full {
    background-image: url('../public/img/bg_pattern_full.svg');
    @media screen and (max-width: 394px) {
      background-image: url('../public/img/bg_pattern.svg');
    }

    @media screen and (min-width: 799px) {
      .match-day__content {
        justify-content: center;
        grid-gap: 40px;
      }

      .match-day__team-title {
        align-self: center;
      }

      .match-day__center-content {
        width: auto;
      }

      .match-day__left-content {
        display: flex;
        align-items: top;
        flex-direction: row-reverse;
        grid-gap: 16px;
        height: 68px;
      }

      .match-day__bets {
        max-width: 540px;
        margin: 0 auto;
      }

      .match-day__right-content {
        display: flex;
        align-items: top;
        grid-gap: 16px;
        height: 68px;
      }

      .match-day__team-title {
        font-size: 18px;
        margin: 0;
      }
    }
  }

  &__avatar-wrapper {
    position: relative;
    display: inline-block;
    align-items: center;
    justify-content: center;
  }

  &__next-period {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    left: -2px;
    top: -2px;

    background: rgba(255, 255, 255, 0.2);
    /* BLUR 8px */

    backdrop-filter: blur(4px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 16px;

    .match-events__tooltip {
      top: 30px;
      padding: 10px;



    }

    &:hover {
      .match-events__tooltip {
        display: block;
      }
    }
  }

  &__expanded-info {
    transition: all 0.3s ease-out;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    grid-gap: 8px 0;

    max-width: 744px;
    margin: 0 auto;
  }

  &__expanded-score-wrapper {
    display: flex;
  }

  &__expanded-score {
    flex: 1 1;
    grid-gap: 4px 0;
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    background: #282F3D;
  }

  &--surebet {
    padding-top: 16px;
  }

  &__score-wrapper {
    display: flex;
    justify-content: center;

    &--white {

      .match-day__score,
      .match-day__score-del {
        color: $white;
      }

    }
  }

  &__timestamp {
    margin-top: 14px;
  }

  &__score-wrapper-small {
    margin-top: 8px;
    font-weight: 700;
    font-size: 11px;
    color: rgba(255, 255, 255, 0.4);
    text-transform: uppercase;
  }

  &__title-small-score {
    // height: 14px;
    line-height: 14px;
  }

  &__score {
    width: 42px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    color: $white;
    font-size: 28px;
    font-family: Montserrat;
    position: relative;

    &--small {
      font-size: 13px;
      width: 18px;
      height: 16px;
      color: rgba(255, 255, 255, 0.4);
    }

    &:hover {
      .match-events__tooltip {
        display: flex;
      }
    }
  }

  &__expanded-score-title {
    color: rgba(255, 255, 255, 0.6);
    font-weight: 700;
    font-size: 11px;
    line-height: 14px;
    /* identical to box height, or 127% */

    text-align: center;
    text-transform: uppercase;
  }

  &__score-del {
    width: 22px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    color: $white;
    font-size: 13px;

    &--small {
      width: 12px;
      font-size: 13px;
      height: 16px;
      color: rgba(255, 255, 255, 0.4);
    }
  }

  &__type-bet {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 16px;
    margin-bottom: 4px;
    text-transform: uppercase;
    font-weight: 700;
    opacity: 0.6;
    font-size: 13px;
    color: $white;
  }

  &__wrapper-bet {
    width: 33.3%;
  }

  &__bets {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__bet-left {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__bet {

    height: 30px;
    background-color: #282F3D;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $white;
    font-weight: 700;
    font-size: 13px;
    cursor: pointer;
    transition: all 0.3s ease-out;

    &:hover {
      background-color: #0B714C;
    }

    &--center {
      .match-day__bet-left {
        width: 100%;
      }
    }

    &--live {
      border-bottom: 1px solid $primary-red-color;

      &:hover {
        background-color: $primary-red-color;
      }

      .match-day__bet-left {
        width: 100%;
      }
    }
  }

  &__badge-title {
    color: $white;
    padding-left: 5px;

    &--live {
      font-size: 13px;
      text-transform: uppercase;
      font-weight: 700;
      color: $primary-red-color;
    }
  }

  &__bet-badge {
    background-color: #0E8D5F;
    height: 100%;
    border-radius: 6px;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    transition: all 0.3s ease-out;
    font-size: 13px;
    min-width: 55px;
  }

  &__all-bets {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 16px;
    margin-top: 16px;
    margin-bottom: 8px;

    .dropdown-matches__ico {
      transition: all 0.3s ease-out;
    }

    &--add {
      cursor: pointer;
    }

    &--expanded {


      .match-day__line--add-info {
        width: 100%;
      }

      .dropdown-matches__ico {
        transform: rotate(0);
      }
    }
  }

  &__line {
    width: 100%;
    height: 1px;
    background: rgba(255, 255, 255, 0.15);
    border-radius: 1px;
    transition: all 0.3s ease-out;

    &--add-info {
      width: 0;
    }
  }

  &__title {
    font-size: 13px;
    line-height: 16px;
    text-transform: uppercase;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.4);
    margin-bottom: 2px;
    position: relative;

    &--all {
      white-space: nowrap;
      margin-left: 16px;
      margin-right: 16px;
      display: flex;
      align-items: center;
      margin-bottom: 0;
      grid-gap: 0 4px;
    }

    &--surebet {
      color: rgba(255, 255, 255, 0.6);
      text-transform: uppercase;
      line-height: 16px;
      font-size: 11px;
      font-weight: 400;
      white-space: nowrap;
    }
  }

  &__team-flag {
    display: inline-block;
  }
  &__team-flag-loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    border-radius: 50%;
    background-color: $bg-fifth;
  }

  &__team-title {
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: $white;
    margin-top: 12px;
  }

  &__subtitle {
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: $white;
    position: relative;

    &::first-letter{
      text-transform: uppercase;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;

    //height: 110px;
    &--surebet {
      height: auto;
      padding-left: 16px;
      padding-right: 16px;
      margin-bottom: 8px;
    }
  }

  &__left-content,
  &__right-content {
    width: 33.3%;
  }

  &__center-content {
    width: 33.3%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &--surebet-match {
    padding-top: 0;
  }
}